<template>
  <div>
    <Navbar />
    <servicesModal :singleEvent="singleEvent"/>

    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-md-0">
        <LeftMenuSide :openTabSet="openTabSet" />
        <div class="row no-gutters content-wrap-info">
          <div :class="{ 'event-section-wrap': true, 'no-live-today': !showLiveToday }">
            <div class="event-section">
              <!-- Sticky Header and Filters -->
              <div :class="['sticky-header', { scrolled: isScrolled }]">
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                  <h6>My Events</h6>
                  <!-- <div class="add-new-team-btn">
                    <button type="button" data-toggle="dropdown">
                      <img :src="add" alt="add-team" />
                      <span  >Add Event</span>
                    </button>
                    <div class="dropdown-menu">
                      <div v-for="team in adminTeamsArray" 
                          :key="team.id" 
                          class="dropdown-item" 
                          @click="callModal(team)">
                            {{ team.teamName }}
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="filter-top">
                  <MyEventsFilter :eventTypes="eventTypes" :associationOptions="associationOptions"
                    :teamsArray="teamsArray" @filters-changed="handleFiltersChanged" />
                </div>
              </div>
              <div class="event-section-content">
                <div class="no-event-wrap" v-if="(!eventsDetails || eventsDetails.length === 0) && showLoader === 0">
                  <img src="../../../assets/Svg/no-data.svg" alt />
                  <p class="header">No events found.</p>
                  <p class="info">We couldn't find any events. Broaden your search by <br /> tweaking the filters above.</p>
                </div>
                <div class="products-list-shim" v-if="eventsDetails.length == 0 && showLoader == 1">
                  <content-placeholders :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                  </content-placeholders>
                  <content-placeholders class="mt-3" :rounded="true">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                  </content-placeholders>
                </div>

                <div class="event-info-wrapper" v-if="eventsDetails.length > 0">
                  <!-- <router-link to="/my-event/details" class="mx-0"> -->
                    <div class="event-info-sec" v-for="(event, index) in eventsDetails" :key="index">
                      <div class="event-sec-top" @click="redirectToEvent(event)">
                        <div class="sec-date-name">
                          <h6>{{ formatEventDate(event?.startDate, event?.endDate) }} ({{ formatTimeZone(event?.time_zone)
                            }})</h6>
                          <span>{{ event?.eventName }}</span>
                        </div>
                        <div class="loc-follow">
                            <div class="loc-content" v-if="event && event.status === '2'">
                                <img :src="link" alt="link" class="mr-1" @click.stop="openInNewTab(event.Url)" />
                                <span @click.stop="openInNewTab(event.Url)">{{ event.Url }}</span>
                            </div>
                            <div class="loc-content" v-else-if="formatLocation(event)">
                                <img :src="location" alt="location" class="mr-1" @click.stop="openGoogleMap($event, event)" />
                                <span  @click.stop="openGoogleMap($event, event)" >{{ formatLocation(event) }}</span>
                            </div>

                            <div class="attendee-sec">
                              <!-- Dropdown Button -->
                              <button 
                                type="button" 
                                @click.stop="toggleDropdown(event.id)" 
                                v-click-outside="() => closeDropdown(event.id)" 
                                class="dropdown-btn" 
                                :class="{ 'selected': selectedOptions[event.id] !== 'Are you going?' }"
                              >
                                <span>{{ selectedOptions[event.id] }}</span>
                              </button>


                              <!-- Dropdown Menu -->
                              <div v-if="showDropdowns[event.id]" class="attendee-dropdown">
                                <button 
                                  v-for="option in options" 
                                  :key="option.value" 
                                  @click.stop="selectOption(event.id, option)"
                                  class="dropdown-option"
                                  :class="{ 'selected': selectedOptions[event.id] === option.label }"
                                >
                                  <span>{{ option.label }}</span>
                                </button>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="event-sec-details">
                        <div class="event-sec-left">
                          <!-- Conditionally display association and event type -->
                          <span class="asso-tour">
                            {{ event.association ? event.association : '' }}
                            {{ event.association && event.eventType ? ' - ' : '' }}
                            {{ event.eventType }}
                          </span>
                          <span class="director">{{ event?.team_name?.team_name }}</span>
                          <div>
                              <MatesEventData :members="event.event_attendee_all" />
                          </div>

                      </div>

                      <div class="event-sec-right">
                        <div class="weather-sec" >
                          <WeatherData v-if="isEventWithinFiveDays(event)" :eventId="event.id" />


                          <!-- <div class="weather-content" v-for="(dataItem, index) in event.weatherData.slice(0, 5)"
                            :key="index">
                            <div class="weather-date">
                              <div class="date-part">{{ formatDateWeather(dataItem.day).date }}</div>
                            </div>
                            <img :src="iconUrl + dataItem.icon" alt="Weather icon" />
                            <div class="tempr">
                              <span class="high">
                                {{ Math.round(dataItem.temperatureMax) }}&deg;F
                              </span>
                              <span class="low">
                                {{ Math.round(dataItem.temperatureMin) }}&deg;F
                              </span>
                            </div>
                          </div> -->
                        </div>
                        <div class="play-info">
                          <div class="game-play">
                            <span>Games</span>
                            <span>{{ event.eventRecord.total_games }}</span>
                          </div>
                          <div class="game-win">
                            <span>Won</span>
                            <span>{{ event.eventRecord.win }}</span>
                          </div>
                          <div class="game-loss">
                            <span>Lost</span>
                            <span>{{ event.eventRecord.lost }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- </router-link> -->
                  <div>
                    <content-placeholders :rounded="true" v-if="showLoader1">
                      <content-placeholders-heading :img="false" />
                    </content-placeholders>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="live-today" :class="{'d-none': isApiLoaded && gameScores.length === 0}">
            <MyEventLiveToday :gameScores="gameScores" :total_game="total_game" :showGameLoader="showGameLoader"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
// import { mapActions } from "vuex";
import * as firestoreService from "@/database/firestore";
import { mapActions, mapGetters } from "vuex";

import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import MyEventsFilter from "@/components/NewGameTime/Events/MyEventsFilter.vue";
import MyEventLiveToday from "@/components/NewGameTime/MyEventLiveToday.vue";
import servicesModal from "@/components/Teams/ChatRight/ChatEvents/servicesModal.vue";
import MatesEventData from "@/components/NewGameTime/Events/MatesEventData.vue";
import WeatherData from "@/components/NewGameTime/Events/WeatherData.vue";
export default {
  name: "eventsDataList",
  components: {
    Navbar,
    LeftMenu,
    Banner,
    LeftMenuSide,
    MyEventsFilter,
    MyEventLiveToday,
    MatesEventData,
    servicesModal,
    WeatherData
  },
  data() {
    return {
      activeAccordionId: -1,
      activeAccordionIndex: -1,
      associationOptions: [],
      // eventsOptions: [],
      eventTypes: [],
      singleEvent: {},
      openTabSet: 1,
      location: require("../../../assets/Svg/location.svg"),
      unfollow: require("../../../assets/Svg/unfollow.svg"),
      follow: require("../../../assets/Svg/follow.svg"),
      add: require("../../../assets/Svg/add.svg"),
      showLiveToday: true,
      filter: {
        name: "",
        association: "",
        selectedYear: "2025",
        eventType: "",
        eventStatus: false,
      },
      TeamA: [],
      sortKey: "",
      iconUrl: "https:",
      sortBy: "",
      reverse: false,
      totalTeams: "",
      teamUsers: "",
      currentIsFan: "0",
      eventsDetails: [],
      UserDetails: [],
      page: 1,
      lastPage: 1,
      showLoader: 1,
      showLoader1: false,
      eventsData: [],
      showGameShimmer: 0,
      gameList: [],
      teamsArray: [],
      isLoadingArrayFilter: [],
      messages: [],
      isLoading: false,
      filterApplied: false,
      previousGoing: false,
      newGameProps: {},
      currentIsAdmin: "0",
      currentTeamIsActive: 1,
      selectedRoomTeam: {},
      currentUserId: "",
      userId: localStorage.getItem("userId"),
      allUsers: [],
      teamLoader: 0,
      // eventTypesUsed: [],
      // allEventTypesUsed: [],
      isScrolled: false,
      // showDropdown: false,
      // selectedOption: "Are you going?",
      options: [
        { label: "Going", value: "going", status: "1" },
        { label: "Not Going", value: "not-going", status: "2" },
        { label: "Maybe", value: "maybe", status: "3" }
      ],
      selectedOptions: {}, // Stores selectedOption for each event by event.id
      showDropdowns: {},
      adminTeamsArray: [],
      showGameLoader: 0,
      gameScores:[],
      gameScoresOngoing:[],
      gameScoresFinal:[],
      total_game: '',
      selectedArray: [],
      room_count: 1,
      adult_count: 1,
      exactRoomEndDate: "",
      note: "",
      exactRoomStartDate: "",
      isApiLoaded: true,

      
    };
  },


  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("events", ["event"]),


  },

  methods: {
    ...mapActions("events", ["setEventListData"]),
    ...mapActions("events", ["getCurrentComingStatus"]),

    redirectToEvent(item) {
      const eventId = item.id;
      this.$router.push({ name: 'SingleEventDetail', params: { id: eventId } });
    },
    
    handleScroll() {
      let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
      this.isScrolled = scrollTop > 5;
    },
    initializeSelectedOptions(eventId, loggedInUserAttendee) {
      if (!loggedInUserAttendee) {
        this.$set(this.selectedOptions, eventId, "Are you going?");
      } else {
        switch (loggedInUserAttendee.member_status) {
          case "1":
            this.$set(this.selectedOptions, eventId, "Going");
            break;
          case "2":
            this.$set(this.selectedOptions, eventId, "Not Going");
            break;
          case "3":
            this.$set(this.selectedOptions, eventId, "Maybe");
          console.log("Maybe")
            break;
          default:
            this.$set(this.selectedOptions, eventId, "Are you going?");
        }
      }
    },
    isEventWithinFiveDays(event) {
  if (!event?.startDate) return false;
  const eventDate = new Date(event.startDate);
  console.log(eventDate, "kskd");

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  eventDate.setHours(0, 0, 0, 0);

  const diffDays = (eventDate - today) / (1000 * 60 * 60 * 24);

  if (diffDays <= 5 && diffDays >= 0) {
    console.log("Event is within the next 5 days! ✅");
    return true;
  }

  return false;
},

     toggleDropdown(eventId) {
      // Toggle dropdown visibility for the specific event
      this.$set(this.showDropdowns, eventId, !this.showDropdowns[eventId]);
    },
    closeDropdown(eventId) {
      // Close dropdown for the specific event
      this.$set(this.showDropdowns, eventId, false);
    },
    selectOption(eventId, option) {
      this.singleEvent={};
      // Update selectedOption for the specific event
      this.$set(this.selectedOptions, eventId, option.label);
      this.closeDropdown(eventId);

      // You can also update the event.loggedInUserAttendee.memberStatus here if needed
      const event = this.eventsDetails.find(e => e.id === eventId);
      if (event) {
        event.loggedInUserAttendee = event.loggedInUserAttendee || {};
        this.previousGoing = event.loggedInUserAttendee?.member_status == 1 ? true : false;
        event.loggedInUserAttendee.member_status = this.getStatusValue(option.value);
      }
      const team_id = event.team_id; // Get team ID
      const event_id = event.id; // Get event ID
      const member_status = option.status;
      this.singleEvent = event;
      this.selectStatus(team_id, event_id, member_status, this.singleEvent);

    },
    getStatusValue(value) {
      // Convert option value to memberStatus value
      switch (value) {
        case "going":
          return "1";
        case "not-going":
          return "2";
        case "maybe":
          return "3";
        default:
          return null;
      }
    },
    toggleAccordion(event, eventId) {
      this.newGameProps = { event };
      if (this.activeAccordionId === eventId) {
        this.eventsDetails.forEach((eventDetail) => {
          if (eventDetail.id === eventId) {
            eventDetail._showDetails = false;
          }
        });
        this.activeAccordionId = -1;
      } else {
        this.eventsDetails.forEach((eventDetail) => {
          eventDetail._showDetails = eventDetail.id === eventId;
        });
        this.activeAccordionId = eventId;
        this.getTeamData(event);
        this.getGames(event);
      }
    },
    formatDateWeather(date) {
      const options = { month: 'short', day: 'numeric' };
      const datePart = new Date(date).toLocaleDateString(undefined, options);
      const yearPart = new Date(date).getFullYear();
      return { date: datePart, year: yearPart };
    },
    handleFiltersChanged(filters) {
      console.log(filters, "ala")
      this.filter = {
        ...this.filter,
        name: filters.name.join(','),
        association: filters.association.join(','),
        eventType: filters.eventType,
        eventStatus: filters.eventStatus,
        selectedYear: filters.year,
      };
      this.page = 1;
      this.eventsDetails = [];
      this.calendarData();
    },
    openInNewTab(url) {
        if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
        }
    },
    async getGames(event) {
      this.showGameShimmer = 1;
      this.gameList = [];
      var formData = new FormData();
      formData.append("event_id", event.id);
      formData.append("team_id", event.team_id);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          event.showGameList = true;
          this.showGameShimmer = 0;
        });
    },
    formatEventDate(startDate, endDate) {
      const options = { month: "short", day: "numeric" }; // Use 'short' for abbreviated months
      const start = new Date(startDate).toLocaleDateString("en-US", options);
      const end = new Date(endDate).toLocaleDateString("en-US", options);
      const year = new Date(endDate).getFullYear();
      return `${start} to ${end}, ${year}`;
    },
    formatTimeZone(timeZone) {
      if (timeZone && typeof timeZone === 'string') {
        return timeZone.replace(/\(UTC[^)]+\)\s*/, "").trim(); // Removes text like "(UTC-06:00)"
      }
      return timeZone; // Return the original value if it's not a valid string
    },
    hasValidLocation(item) {
      // Check if any of the address, city, or state fields are valid
      return (
        (item.status == 1 &&
          item.address != null &&
          item.address.trim() !== "" &&
          item.address !== "null") ||
        (item.city != null && item.city.trim() !== "" && item.city !== "null") ||
        (item.state != null && item.state.trim() !== "" && item.state !== "null")
      );
    },
    formatLocation(item) {
      // Combine address, city, and state into a readable location string
      const parts = [
        item.city ? item.city.trim() : "",
        item.state ? item.state.trim() : "",
      ];
      return parts.filter(Boolean).join(", ");
    },

    async firebasedata(user_id) {
      this.TeamDetails = [];
      this.Team = [];
      const querySport = firestoreService.roomsSportQuery(user_id);
      const { data, docs } = await firestoreService.getRooms(querySport);
      this.totalTeams = docs[docs.length - 1];
      data.forEach((teams) => {
        this.TeamDetails.push(teams.id);
        this.TeamA.push(teams);
        //  Make Array for all Users on Team Start -----------
        const rawUsers = [];
        teams.users.forEach((userId) => {
          const promise = firestoreService.getUser(userId);
          rawUsers.push(promise);
        });
        this.teamUsers = "";
        var currentIsFan = "0";
        Promise.all(rawUsers).then((values) => {
          for (const role of teams.userRole) {
            for (const member of teams.users) {
              if (role.id == member) {
                if (member == this.currentUserId && role.fan == "1") {
                  currentIsFan = "1";
                }
                if (member == this.currentUserId && role.fan != "1") {
                  currentIsFan = "0";
                }
              }
            }
          }
          let teamDeta = {
            teamID: teams.id,
            users: values,
            userRole: teams.userRole,
            currentIsFan: currentIsFan,
          };

          this.UserDetails.push(teamDeta);
        });
        //  Make Array for all Users on Team Start -----------
      });

      this.setEventListData(this.TeamDetails);

      this.calendarData();

      let comingStatus = {
        teamID: this.TeamDetails,
        usersDetail: this.UserDetails,
        users: user_id,
      };
      this.getCurrentComingStatus(comingStatus);
    },
    async getUserChatIdWithRetry() {
      const userId = localStorage.getItem("userId");

      const promiseUser = await firestoreService.getUserCid(userId);

      this.currentUserId = promiseUser.data[0].id;

      this.firebasedata(this.currentUserId);

      // setTimeout(() => {
      //   this.getUserChatIdWithRetry();
      // }, 1000);
    },
    async getUserChatId() {
      await this.getUserChatIdWithRetry();
    },

    async calendarData() {
      this.page = 1;
      // Set the default filter if not already set
      if (this.filter.eventStatus === null) {
        this.filter.eventStatus = "true"; // Default to upcoming events
      }

      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("userId"));
      formData.append("eventStatus", this.filter.eventStatus);
      formData.append("eventType", this.filter.eventType); // Add event type filter
      formData.append("association", this.filter.association); // Add association filter // Add event status filter
      formData.append("teamName", this.filter.name); // Add association filter // Add event status filter
      formData.append("selectedYear", this.filter.selectedYear); // Add association filter // Add event status filter
      // if (
      //   this.filter.name ||
      //   this.filter.association ||
      //   this.filter.eventType
      // ) {
      //   this.page = 1;
      //   this.eventsDetails = [];
      //   this.filterApplied = true;
      // }

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
          `event/calenderEventListss?page=${this.page}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );


        if (response?.data?.statusCode === 200) {
          // console.log(response.data.data,"sdjks")
          this.commonEventTypes = response.data.data.eventTypes;
          const newEventData = response.data.data.listing;
          this.eventTypes = response.data.data.eventTypes;
          // If it's the first page, clear eventsDetails array
          // if (this.page === 1) {
          //  this.eventsDetails = [];
          //}

          // Concatenate newly fetched data with existing data
          this.eventsDetails = this.eventsDetails.concat(newEventData);
          this.eventsDetails.forEach(event => {
            this.initializeSelectedOptions(event.id, event.loggedInUserAttendee);
          });

          // Filter events based on selected eventType
          if (this.filter.eventType) {
            this.eventsDetails = this.eventsDetails.filter(event => {
              // Ensure we are filtering by eventType only, not location
              return event.eventType === this.filter.eventType;
            });
          }

          this.eventsDetails.sort((a, b) => {
            const dateAStart = new Date(a.exactStartDate);
            const dateBStart = new Date(b.exactStartDate);
            const dateAEnd = new Date(a.exactEndDate);
            const dateBEnd = new Date(b.exactEndDate);

            // Compare start dates first
            if (dateAStart.getTime() !== dateBStart.getTime()) {
              return dateAStart - dateBStart;
            } else {
              // If start dates are the same, compare end dates
              return dateAEnd - dateBEnd;
            }
          });
          this.filterOption = false;
          this.total = response.data.data.total;
          this.lastPage = response.data.data.listing.last_page;
          this.noEvent = this.eventsDetails.length > 0 ? 0 : 1;
        } else {
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
          console.error(
            "Error:",
            response.data.message || "Unknown error occurred"
          ); this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.errorMessage = "Failed to fetch data";
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },
    async fetchAllTeamsFromFirestore() {
      try {
        var UserId = localStorage.getItem("userId");
        const promiseUser = await firestoreService.getUserCid(
          UserId.toString()
        );

        if (promiseUser.data[0]?.id !== undefined) {
          var currentUserId = promiseUser.data[0].id;

          // Assuming getAllRooms fetches all rooms and returns an object with a 'data' array
          const roomsData = await firestoreService.getAllRooms();

          if (Array.isArray(roomsData.data) && roomsData.data.length > 0) {
            const allTeams = roomsData.data.filter((room) => {
              // Filter and collect all teams associated with the user
              return (
                room.userRole &&
                room.userRole.some((user) => user.id === currentUserId) &&
                room.teamType === "sports"
              );
            });

            const adminTeams = allTeams.filter((room) => {
              // console.log("Checking Room:", room);
              // console.log("Room User Role:", room.userRole);

              return (
                room.teamType === "sports" &&
                room.userRole?.some((user) => {
                  // console.log("User Data:", user);
                  return user.id === currentUserId && Number(user.admin) === 1
                })
              );
            });

            // console.log("Filtered Admin Teams:", adminTeams)

            if (allTeams.length > 0) {
              this.teamsArray = allTeams;
              this.adminTeamsArray = adminTeams;

              // console.log(this.adminTeamsArray,'fdgfd')
              return allTeams;
            } else {
              console.log("User is not associated with any teams.");
            }
          } else {
            console.error("No data found or data is not an array");
          }
        }
      } catch (error) {
        console.error("Error fetching teams from Firestore:", error);
        // Handle errors appropriately
      }
    },

    clearEventType() {
      this.filter.eventType = "";
      this.calendarData();
    },

    clearAssociation() {
      this.filter.association = "";
      this.calendarData();
    },

    clearTeam() {
      this.filter.name = "";
      this.calendarData();
    },

    getAssociation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociations?search=${""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationOptions = [];
          this.associationOptions = response.data.data.associations;

        });
    },

    // getEventsType() {
    //   axios
    //     .get(`${process.env.VUE_APP_API_URL}event/getEventTypes?search=${""}`)
    //     .then((response) => {
    //       this.eventsOptions = response.data.data.map(
    //         (element) => element.name
    //       );
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching event types:", error);
    //     });
    // },

    handleScrolledToButtom(isVisible) {
      console.log("hittinh ", this.page, this.lastPage)

      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.calendarData2();
      }
    },
    async calendarData2() {
      // Set the default filter if not already set
      if (this.filter.eventStatus === null) {
        this.filter.eventStatus = "true"; // Default to upcoming events
      }

      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      const formData = new FormData();
      // formData.append("sportsRoomID", this.TeamDetails);
      formData.append("user_id", localStorage.getItem("userId"));
      formData.append("selectedYear", this.filter.selectedYear); // Add association filter // Add event status filter
      formData.append("eventStatus", this.filter.eventStatus);
      formData.append("eventType", this.filter.eventType); // Add event type filter
      formData.append("association", this.filter.association); // Add association filter // Add event status filter
      formData.append("teamName", this.filter.name); // Add association filter // Add event status filter
      // if (
      //   this.filter.name ||
      //   this.filter.association ||
      //   this.filter.eventType
      // ) {
      //   this.page = 1;
      //   this.eventsDetails = [];
      //   this.filterApplied = true;
      // }

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
          `event/calenderEventListss?page=${this.page}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );


        if (response.data.statusCode === 200) {
          this.commonEventTypes = response.data.data.eventTypes;
          const newEventData = response.data.data.listing.data;
          this.eventTypes = response.data.data.eventTypes;
          // this.eventTypesUsed = [...new Set(newEventData.map(event => event.eventType))];
          // this.allEventTypesUsed = [...new Set([...this.allEventTypesUsed, ...this.eventTypesUsed])];

          // if (!this.filterApplied) {
          //   this.commonEventTypes = this.allEventTypesUsed.filter(eventType =>
          // this.eventsOptions.includes(eventType)
          // );
          // }

          // If it's the first page, clear eventsDetails array
          //if (this.page === 1) {
          //  this.eventsDetails = [];
          //}

          // Concatenate newly fetched data with existing data
          this.eventsDetails = this.eventsDetails.concat(newEventData);
          this.eventsDetails.forEach(event => {
            this.initializeSelectedOptions(event.id, event.loggedInUserAttendee);
          });


          // Filter events based on selected eventType
          if (this.filter.eventType) {
            this.eventsDetails = this.eventsDetails.filter(event => {
              // Ensure we are filtering by eventType only, not location
              return event.eventType === this.filter.eventType;
            });
          }

          this.eventsDetails.sort((a, b) => {
            const dateAStart = new Date(a.exactStartDate);
            const dateBStart = new Date(b.exactStartDate);
            const dateAEnd = new Date(a.exactEndDate);
            const dateBEnd = new Date(b.exactEndDate);

            // Compare start dates first
            if (dateAStart.getTime() !== dateBStart.getTime()) {
              return dateAStart - dateBStart;
            } else {
              // If start dates are the same, compare end dates
              return dateAEnd - dateBEnd;
            }
          });
          this.filterOption = false;
          this.total = response.data.data.total;
          this.lastPage = response.data.data.listing.last_page;
          this.noEvent = this.eventsDetails.length > 0 ? 0 : 1;
        } else {
          console.error(
            "Error:",
            response.data.message || "Unknown error occurred"
          ); this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.errorMessage = "Failed to fetch data";
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },
    openGoogleMap(event, item) {
      event.preventDefault(); // Prevents <router-link> navigation
      event.stopPropagation(); // Stops event bubbling
      console.log(item, "hdhhd")
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];
      console.log(urlSuffix, " urlSuffix")
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    // callModal(game, event) {
    //   var combineUrl = event.team_id + "-" + game.guid;
    //   const urlToOpen = `/score-game/${combineUrl}`;
    //   window.location.href = urlToOpen;
    // },
    togglePastEventsFilter() {
      this.eventsDetails = [];
      this.page = 1;
      this.calendarData();
    },

    async getTeamData(event) {
      this.teamLoader = 1;
      const rawRoom = [];
      const promise = await firestoreService.getRoomById(event.team_id);
      rawRoom.push(promise);

      const foundRoom = await Promise.all(rawRoom);
      let usersData = {};
      Object.keys(foundRoom).forEach((key) => {
        const teamData = foundRoom[key];
        usersData = teamData.users;
        teamData.users = {};
        this.selectedRoomTeam = teamData;
      });

      // check role if team exits or user is member
      let roles = this.selectedRoomTeam.userRole;
      if (usersData != undefined) {
        var admincount = 0;
        for (const userRole of roles) {
          if (userRole.id == this.currentUserId) {
            admincount++;
          }
        }
        if (admincount == 0) {
          this.$router.push({ name: "PageNotFound" });
        }
      } else {
        this.$router.push({ name: "PageNotFound" });
      }

      await this.makeUserArray(usersData);
      const users = [];
      usersData.forEach((userId) => {
        const foundUser = this.allUsers.find((user) => user?._id === userId);
        if (foundUser) {
          users.push(foundUser);
        }
      });

      this.selectedRoomTeam.users = users;

      // assign admin role
      let tempMembers = this.selectedRoomTeam.users;

      // Set Users Role and Find if current User is admin
      for (const role of roles) {
        for (const member of tempMembers) {
          if (role.id == member.id) {
            member.adminRole = role.admin;
            member.fanRole = role.fan;
            member.roleStatus = role.status;
            member.uniform = role.uniform;
            member.bat_size = role.bat_size;
            member.bat_details = role.bat_details;
            member.hat_size = role.hat_size;
            member.shirt_size = role.shirt_size;
            member.pant_size = role.pant_size;
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "1"
          ) {
            this.currentIsAdmin = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "0"
          ) {
            this.currentIsAdmin = "0";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole == "1"
          ) {
            this.currentIsFan = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole != "1"
          ) {
            this.currentIsFan = "0";
          }

          // assign current user team status
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 1
          ) {
            this.currentTeamIsActive = 1;
          }
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 2
          ) {
            this.currentTeamIsActive = 2;
          }
        }
      }

      this.selectedRoomTeam.roomId = this.selectedRoomTeam.id;
      this.teamLoader = 0;
    },
    getLiveScore() {
    this.showGameLoader = 1;
    this.gameScoresOngoing = [];
    this.gameScoresFinal = [];
    this.isApiLoaded = false;

    axios
        .post(process.env.VUE_APP_API_URL + "event/fetchGamesForUser", {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            if (res.data.statusCode === 200) {
                this.gameScores = res.data.data.listing || [];
                this.total_game = res.data.data.total_games || 0;

                if (this.gameScores.length > 0) {
                    this.event_name = this.gameScores[0].event_name;
                    this.count = 0;

                    this.gameScores.forEach((gameScore) => {
                        if (gameScore.game_status !== 2) {
                            this.gameScoresOngoing.push(gameScore);
                            this.count++;
                        } else {
                            this.gameScoresFinal.push(gameScore);
                        }
                    });
                }
            } else {
                console.error("Error:", res.data.message || "Failed to fetch games.");
            }
        })
        .catch((error) => {
            console.error("API Error:", error.response?.data?.message || error.message);
        })
        .finally(() => {
            this.showGameLoader = 0;
            this.isApiLoaded = true;
        });
},

    async makeUserArray(users) {
      const roomUserIds = users;

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },
    async selectStatusWithServices(status) {
      status = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      formData.append("member_status", status);
      formData.append("services", this.selectedArray);
      formData.append("team_id", this.singleEvent.team_id);
      formData.append("room_count", this.room_count);
      formData.append("exactStartDate", this.exactRoomStartDate);
      formData.append("exactEndDate", this.exactRoomEndDate);
      formData.append("note", this.note);
      formData.append("adult_count", this.adult_count);
      if (
        this.userMember_id !== null &&
        this.userMember_id !== undefined &&
        this.userMember_id !== ""
      ) {
        formData.append("member_id", this.userMember_id);
      }
      await axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          // this.getAttendee();
        });
    },

    openEditTravel() {
      if (this.Services) {
        this.$root.$emit("openEditServicesModal", {
          event: this.singleEvent,
          services: this.Services,
        });
      }
    },
    async selectStatus(team_id, event_id, member_status, singleEvent) {
      if (this.previousGoing && member_status == 1) {
        return;
      }
      if (member_status == 1) {
        this.$root.$emit("openServicesModal", singleEvent);
      } else {
        this.editTravel = false;
        
        // Create form data
        var formData = new FormData();
        formData.append("event_id", event_id);
        formData.append("member_status", member_status);
        formData.append("team_id", team_id);

        try {
          await axios.post(
            process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // this.getAttendee(); // Refresh attendee list after success
        } catch (error) {
          console.error("Error updating status:", error);
        }
      }
    },

  },
mounted() {
  this.getLiveScore();
    this.calendarData();
    let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScroll);
    }
    // this.getUserChatIdWithRetry();
    this.getAssociation();

    // this.getEventsType();
    this.fetchAllTeamsFromFirestore();
    this.$root.$on("refreshEventGame", (event) => {
      this.getGames(event);
    });
    this.$root.$on("openTabSetting", (id) => {
      this.openTabSet = id;
    });
    this.$root.$on(
    "attendeesServiceForMyEvent",
    (
      data,
      room_count,
      adultQuantity,
      note,
      exactRoomStartDate,
      exactRoomEndDate
    ) => {
      this.selectedArray = data;
      this.room_count = room_count;
      this.note = note;
      this.adult_count = adultQuantity;
      this.exactRoomEndDate = exactRoomEndDate;
      this.exactRoomStartDate = exactRoomStartDate;
      this.selectStatusWithServices();
    }
  );

  },
  beforeUnmount() {
    let scrollContainer = document.querySelector('.shop-side');
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  },

};
</script>
