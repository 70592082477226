<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />

            <div class="shop-side p-md-0">
                <LeftMenuSide :openTabSet="openTabSet" />
                <div class="row no-gutters content-wrap-info">
                    <div class="event-section-wrap team-section-wrap">
                        <div class="event-section">
                            <div :class="['sticky-header', { scrolled: isScrolled }]">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h6>Teams I Follow</h6>
                                    <div class="form-inline bg-white">
                                        <button>
                                        <img class="search-image" :src="search" alt="search" />
                                        </button>
                                        <input v-model="filter.name" aria-label="Search"  @input="debouncedGetFilters()"
                                          class="form-control border-0 bg-white" placeholder="Search" 
                                          type="search" v-on:keyup.enter="searchValue" />

                                    </div>
                                </div>
                                <div class="filter-top">
                                    <FollowingTeamsFilter :ageOptions="ageOptions" :ratingOptions="ratingOptions"
                                    @follow-filters-changed="handleFollowFiltersChanged" />
                                </div>
                            </div>
                            
                            <div class="event-section-content">
                                <div class="no-event-wrap" v-if="followingTeams.length === 0 && showLoader === 0">
                                    <img src="../../../assets/Svg/no-data.svg" alt />
                                    <p class="header">No followed teams found.</p>
                                    <p class="info">Teams you follow will appear here. Change the filter <br/> above or Head over to <router-link exact to="/discover-teams">Discover Teams</router-link> to find <br /> something interesting.</p>
                                </div>

                                <div class="products-list-shim" v-if="followingTeams.length == 0 && showLoader == 1">
                                <content-placeholders :rounded="true">
                                    <content-placeholders-img />
                                    <content-placeholders-heading />
                                </content-placeholders>
                                <content-placeholders class="mt-3" :rounded="true">
                                    <content-placeholders-img />
                                    <content-placeholders-heading />
                                </content-placeholders>
                                </div>
                                <div class="event-info-wrapper" v-if="followingTeams.length > 0">
                                <div class="event-info-sec" v-for="(team, index) in followingTeams" :key="index">
                                    <div class="event-sec-top team-sec-top">
                                    <div class="team-sec-left">
                                        <div class="image-team">
                                        <img @load="onImageLoad" v-if="team.team_avatar" :src="url + team.team_avatar" alt=""
                                            style="border: 2px solid white" />
                                        <img @load="onImageLoad" v-else alt="" src="../../../assets/Svg/team-avatar.svg" />
                                        </div>
                                        <div class="sec-date-name">
                                        <div class="d-flex">
                                            <h6>{{team?.team_name}}</h6>
                                            <img v-if="team?.wif_approved_status === 1" class="ml-2" alt="WIF Approved"
                                            :src="wif_approved" />
                                        </div>
                                        <span>{{team?.age_group.name}}- {{team?.ratings?.rate}} -
                                            {{team.gender}}</span>
                                        <div class="loc-content">
                                            <img :src="location" alt="location" />
                                            <span>{{team?.city}},{{ team?.state }}</span>
                                        </div>
                                        <span v-if="team?.last_updated">Last Updated: {{ team?.last_updated | formatDateOfBirth
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="loc-follow">

                                        <div class="follow-unfollow-btns w-100 d-flex justify-content-end">
                                        <template v-if="!team.showLoader">
                                            <template v-if="team && team?.team_follower">
                                            <button class="follow-btn" @click.stop="unFollowTeam(team)">
                                                <img :src="follow" alt="follow" />
                                            </button>
                                            </template>
                                            <template v-else>
                                            <button class="unfollow-btn" @click.stop="followTeam(team)">
                                                <img :src="unfollow" alt="unfollow" />
                                                <span>Follow</span>
                                            </button>
                                            </template>
                                        </template>

                                        <!-- Show loader when team.showLoader is true -->
                                        <div v-if="team.showLoader" class="d-flex justify-content-end">
                                            <img src="../../../assets/loader.gif" alt="" width="35" />
                                        </div>
                                        </div>
                                        <!-- <div class="follow-unfollow-btns">
                                            <button class="unfollow-btn">
                                                <img :src="unfollow" alt="unfollow" />
                                                <span>Follow</span>
                                            </button>
                                            <button class="follow-btn">
                                                <img :src="follow" alt="follow" />
                                            </button>
                                        </div> -->
                                        <div class="team-email-phone">

                                        <div class="team-email" v-if="team?.email">
                                            <span>{{team?.email}}</span>
                                            <img :src="sms" alt="sms" />
                                        </div>
                                        <div class="team-number" v-if="team?.phone_number">
                                            <span>{{team.phone_number}}</span>
                                            <img :src="phone" alt="phone" />
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div v-if="followingTeams.length" v-observe-visibility="handleScrolledToButtom"></div>
                                <div class="p-3">
                                    <content-placeholders :rounded="true" v-if="showLoader">
                                    <content-placeholders-heading :img="false" />
                                    </content-placeholders>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import FollowingTeamsFilter from "@/components/NewGameTime/Teams/FollowingTeamsFilter.vue";
import axios from "axios";

export default {
    name: "DiscoverTeams",
    components: {
        Navbar,
        LeftMenu,
        Banner,
        LeftMenuSide,
        FollowingTeamsFilter,
    },
    data() {
        return {
            openTabSet: 2,
            location: require("../../../assets/Svg/location.svg"),
            unfollow: require("../../../assets/Svg/unfollow.svg"),
            follow: require("../../../assets/Svg/follow.svg"),
            search: require("../../../assets/Svg/Search.svg"),  
            teams: require("../../../assets/Svg/team.svg"),
            sms: require("../../../assets/Svg/sms.svg"),  
            phone: require("../../../assets/Svg/mobile.svg"),
            wif_approved: require("../../../assets/Svg/shield-tick.svg"),
            searchTerm: "",
            sortKey: "",
            url: this.$groupAvatarStorageURL,
            reverse: false,
            teamsAll: [],
            messages: [],
            page: 1,
            lastPage: 1,
            followingTeams: [],
            showLoader: 0,
            showLoader1: false,
            ageOptions: [],
            genderOptions: [
                { name: "Male", id: "Male" },
                { name: "Female", id: "Female" },
                { name: "Coed", id: "Coed" },
            ],
            filter: {
                name: "",
                age: "",
                rating: "",
                gender: "",
            },
            ratingOptions: [],
            isLoading : false,
            isScrolled : false,
            user_id: localStorage.getItem("userId"),
            debounceTimer: null,
        };
    },
    methods: {
    handleScroll() {
      let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
      this.isScrolled = scrollTop > 5;
    },
    clearName() {
      this.filter.name = "";
      this.getFilters();
    },
    clearAge() {
      this.filter.age = "";
      this.getFilters();
    },
    clearGender() {
      this.filter.gender = "";
      this.getFilters();
    },
    clearRating() {
      this.filter.rating = "";
      this.getFilters();
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    debouncedGetFilters() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getFilters();
      }, 800); 
    },
    async getFilters() {
      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      this.followingTeams = [];
      try {
        const filteredteams = await axios.post(
          `${process.env.VUE_APP_API_URL}follower/getFollowers`,
          {
            name: this.filter.name,
            age: this.filter.age,
            gender: this.filter.gender,
            teams: this.filter.teams,
            states: this.filter.states,
            rating: this.filter.rating,
            page: this.page,
            user_id: this.user_id,
          }
        );

        if (filteredteams.data.statusCode === 200) {
          const newEventData = filteredteams.data.data.data;
          if (this.page === 1) {
            this.followingTeams = [];
          }
          this.followingTeams = this.followingTeams.concat(newEventData);

          // this.followingTeams = filteredteams.data.data.data;
          this.lastPage = filteredteams.data.data.last_page;
          this.showLoader = 0;
          this.showLoader1 = false;
          this.filterOption = false;
          this.isLoading = false; // Close the popup after successful filtering
        } else {
          this.errorMessage = filteredteams.data.message;
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
        this.showLoader1 = false;
      } 
    },
    handleFollowFiltersChanged(filters) {
     console.log(filters,"ala")
      this.filter = {
        ...this.filter,
        age: filters.ages.join(','),
        gender: filters.gender,
        teams: filters.teams.join(','),
        states: filters.states
      };
      this.page = 1; // Reset pagination
      this.getFilters();
    },

    getAgeGroup() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAgeGroup`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ageOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    getAllRating() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAllRatings`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ratingOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },

    handleScrolledToButtom(isVisible) {
      console.log(this.page)
      console.log(this.lastPage)
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.getFollowTeam();
      }
    },

    async followTeam(team) {

      this.$set(team, 'showLoader', true);

      try {
        const formData = new FormData();
        formData.append("team_id", team.id);
        formData.append("user_id", this.user_id);

        // API Call
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}follower/store`,
          formData
        );

        if (response.data.statusCode === 200) {
          const teamFollow = response.data.data;
          console.log(teamFollow);
          this.$set(team, 'team_follower', teamFollow);



          console.log(this.teamsData);
          this.$set(team, 'showLoader', false);  // Stop the loader after the update
        } else {
          this.errorMessage = response.data.message || "Failed to follow the team.";
        }
      } catch (error) {
        console.error("API Error:", error);
        this.errorMessage = "Failed to fetch data. Please try again.";
      } finally {
        // Ensure loader state is set to false even if an error occurs
        if (team.showLoader) {
          this.$set(team, 'showLoader', false);
        }
      }
    },

    async unFollowTeam(followTeam) {

        this.$set(followTeam, 'showLoader', true);

        try {
          const formData = new FormData();
          formData.append("follow_id", followTeam.team_follower.id);  // Use followTeam's follower id

          // API Call
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}follower/unfollow`,
            formData
          );

          if (response.data.statusCode === 200) {
            const updatedFollowTeams = this.followingTeams.filter(team => team.id !== followTeam.team_follower.team_id);

            // Remove the team_follower from the team object locally (instantly)
            this.$set(this, 'followingTeams', updatedFollowTeams);  // Or delete team.team_follower
          } else {
            this.errorMessage = response.data.message || "Failed to unfollow the team.";
          }
        } catch (error) {
          console.error("API Error:", error);
          this.errorMessage = "Failed to fetch data. Please try again.";
        } finally {
          // Remove loading state for this team
          this.$set(followTeam, 'showLoader', false); // Ensure loader state is set to false reactively
        }
      
    },

    async getFollowTeam() {
      this.showLoader = 1;
      this.showLoader1 = true;
      
      try {
        const formData = new FormData();
        formData.append("user_id", this.user_id);
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}follower/getFollowers?page=${this.page}`, formData
        );

        if (response.data.statusCode === 200) {
          this.followingTeams.push(...response.data.data.data);
          this.lastPage = response.data.data.last_page;
          this.showLoader = 0;
          this.showLoader1 = false;
        } else {
          this.errorMessage = response.data.message;
          this.showLoader = 0;
          this.showLoader1 = false;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
        this.showLoader1 = false;
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
      }
    },
  },
  mounted() {
    this.getAgeGroup();
    this.getAllRating();
    this.getFollowTeam();
    this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
    let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
    if (scrollContainer) {
        scrollContainer.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    let scrollContainer = document.querySelector('.shop-side');
    if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  },
    
};
</script>