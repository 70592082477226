<template>
    <div class="filter-container">
      <!-- Year Dropdown -->
       <div class="filter-cotent">
         <!-- Organization Dropdown (Multi-select) -->
         <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('age')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedAges.length > 0
        }"
          @click="toggleDropdown('age')"
        >
  
          <span>{{ selectedAges.length >= 1 ?  getAgeName(selectedAges[0]) : 'Age Group' }}</span>
          <div class="badge-wrapper" v-if="selectedAges.length > 1">
            <span class="counter-badge">+{{ selectedAges.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'age'">
          <div
             v-for="age in ageOptions"
            :key="age.id"
            class="dropdown-item"
            :class="{ 'selected': selectedAges.includes(age.id) }"
            @click="toggleAgeSelection(age)"
          >
            <input 
              type="checkbox" 
              :checked="selectedAges.includes(age.id)"
              class="checkbox"
            >
           <span> {{ age.name }} </span>
          </div>
        </div>
      </div>
  
      <!-- Organization Dropdown (Multi-select) -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('team')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedTeams.length > 0
        }"
          @click="toggleDropdown('team')"
        >

          <span>{{ selectedTeams.length >= 1 ? getRatingName(selectedTeams[0]) : 'Rating' }}</span>
          <div class="badge-wrapper" v-if="selectedTeams.length > 1">
            <span class="counter-badge">+{{ selectedTeams.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'team'">
          <div
            v-for="team in ratingOptions"
            :key="team.id"
            class="dropdown-item"
            :class="{ 'selected': selectedTeams.includes(team.id) }"
            @click="toggleTeamSelection(team)"
          >
            <input 
              type="checkbox" 
              :checked="selectedTeams.includes(team.id)"
              class="checkbox"
            >
           <span> {{ team?.rate }} </span>
          </div>
        </div>
      </div>

      <div class="dropdown-wrapper">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedGender
        }"
          @click="toggleDropdown('gender')"
        >
          <span>{{ selectedGender || 'Gender' }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'gender'">
          <div
            v-for="gender in genders"
            :key="gender"
            class="dropdown-item"
            @click="selectGender(gender)"
          >
            <span>{{gender}}</span>
          </div>
        </div>
      </div>
  
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('state')">
    <button 
      class="dropdown-button"
      :class="{ 'has-value': selectedStates.length > 0 }"
      @click="toggleDropdown('state')"
    >

      <span>{{ selectedStates.length >= 1 ? selectedStates[0] : 'State' }}</span>
      <div class="badge-wrapper" v-if="selectedStates.length > 1">
        <span class="counter-badge">+{{ selectedStates.length - 1 }}</span>
      </div>
      <img :src="arrow_down" alt="arrow-down" />
    </button>
    <div class="dropdown-menu-content" v-show="activeDropdown === 'state'">
      <div
        v-for="state in stateOptions"
        :key="state.id"
        class="dropdown-item"
        :class="{ 'selected': selectedStates.includes(state.name) }"
        @click="toggleStateSelection(state)"
      >
        <input 
          type="checkbox" 
          :checked="selectedStates.includes(state.name)"
          class="checkbox"
        >
        <span>{{ state.name }}</span>
      </div>
    </div>
  </div>
  
      <!-- <button class="filter-button">All Filters</button> -->
    </div>
      <button class="reset-button" @click="resetFilters" v-if="isResetVisible">Reset Filters</button>
    </div>
  </template>
  
  <script>
  let states = require("../../../assets/js/state");
  export default {
    name: 'DiscoverTeamsFilter',
    directives: {
      'click-outside': {
        bind(el, binding) {
          el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
              binding.value(event);
            }
          };
          document.addEventListener('click', el.clickOutsideEvent);
        },
        unbind(el) {
          document.removeEventListener('click', el.clickOutsideEvent);
        },
      },
    },
    props: {
      ageOptions: {
      type: Array
    },
    ratingOptions: {
      type: Array
    },
  
  },
    data() {
      return {
        activeDropdown: null,
        selectedAges: [],
        selectedTeams: [],
        selectedGender: '',
        selectedStates: [],
        selectedEmitStates: [],
        ages: [],
        teams: [],
        genders: [
          'Male',
          'Female',
          'Coed'
        ],
        stateOptions: states,
        arrow_down: require("../../../assets/Svg/arrow-down.svg"),
      };
    },
    computed: {
      isResetVisible() {
        return (
          this.selectedAges.length > 0 ||
          this.selectedTeams.length > 0 ||
          this.selectedGender !== '' ||
          this.selectedStates.length > 0 ||
          this.selectedEmitStates.length > 0 
        );
      }
    },
    methods: {
      toggleDropdown(dropdownName) {
        this.activeDropdown = this.activeDropdown === dropdownName ? null : dropdownName;
      },
      closeDropdown(dropdownName) {
        if (this.activeDropdown === dropdownName) {
          this.activeDropdown = null;
        }
      },
      emitFilters() {
        console.log(this.selectedAges)
        console.log(this.selectedStates)
      this.$emit('filters-changed', {
        ages: this.selectedAges,
        teams: this.selectedTeams,
        gender: this.selectedGender,
        states: this.selectedEmitStates
      });
    },

    toggleAgeSelection(age) {
      const ageName = age.id; // Get age name from object
      const index = this.selectedAges.indexOf(ageName);
      if (index === -1) {
        this.selectedAges.push(ageName);
      } else {
        this.selectedAges.splice(index, 1);
      }

      this.emitFilters();
      // this.closeDropdown('age');
    },
    getAgeName(stateId) {
      const ageName = this.ageOptions.find((option) => option.id === stateId);
      return ageName ? ageName.name : ''; // Return the name if found
    },
    getRatingName(stateId) {
      console.log(stateId,'dkdk')
      const rating = this.ratingOptions.find((option) => option.id === stateId);
      return rating ? rating.rate : ''; // Return the name if found
    },
    toggleTeamSelection(team) {
   
     const teamName = team.id; // Get age name from object
      const index = this.selectedTeams.indexOf(teamName);
      if (index === -1) {
        this.selectedTeams.push(teamName);
      } else {
        this.selectedTeams.splice(index, 1);
      }
      this.emitFilters(); 

      // this.closeDropdown('team');
    },

    selectGender(gender) {
      this.selectedGender = gender;
      this.emitFilters();
      this.closeDropdown('gender'); // Add this
    },

    toggleStateSelection(state) {
      const stateName = state.name; 
      const stateData = {
      name: state.name,
      abbreviation: state.abbreviation
    };
      const index = this.selectedStates.indexOf(stateName);
      console.log(this.selectedStates[0],"dd");
      if (index === -1) {
        this.selectedStates.push(stateName);
        this.selectedEmitStates.push(stateData);
      } else {
        this.selectedStates.splice(index, 1);
        this.selectedEmitStates.splice(index, 1);
      }
      this.emitFilters(); 
      // this.closeDropdown('state');
    },

      resetFilters() {
        this.selectedAges = [];
        this.selectedTeams = [];
        this.selectedGender = '';
        this.selectedStates = [];
        this.selectedEmitStates = [];
        this.emitFilters(); 
      },
    },
  };
  </script>