<template>
    <div>
        <Navbar />
        <div class="setting-main-wrapper">
            <div class="main-left-menu">
                <LeftMenu />
            </div>
            <Banner />

            <div class="shop-side p-md-0">
                <LeftMenuSide :openTabSet="openTabSet" />
                <div class="row no-gutters content-wrap-info">
                    <div class="event-section-wrap">
                        <div class="event-section">
                            <div :class="['sticky-header', { scrolled: isScrolled }]">
                                <h6>Discover Events</h6>
                                <div class="filter-top">
                                    <DiscoverEventsFilter :associationOptions="associationOptions" :showLoader="showLoader"
                                    @filters-changed="handleFiltersChanged" />
                                </div>
                            </div>
                            <div class="event-section-content">
                                    <div v-if="eventsData.length > 0" class="event-info-wrapper pb-0">  
                                        
                                        <div class="event-info-sec" v-for="(event, index) in eventsData" :key="index">
                                            <div class="event-sec-top" @click="redirectToEvent(event)">
                                                <div class="sec-date-name">
                                                    <!-- Display event date range using the start and end date -->
                                                    <h6>
                                                        {{ formatDateRange(event.exactStartDate, event.exactEndDate) }} <span class="mt-2" v-if="event?.time_zone"> - ({{ formatTimeZone(event.time_zone) }}) </span>
                                                    </h6>
                                                    <span>{{ event.eventName }}</span>
                                                </div>
                                                <div class="loc-follow w-100">
                                                    <div class="loc-content" v-if="event && event.status === '2'">
                                                        <img :src="link" alt="link" class="mr-1" @click.stop="openInNewTab(event.Url)" />
                                                        <span @click.stop="openInNewTab(event.Url)">{{ event.Url }}</span>
                                                    </div>
                                                    <div class="loc-content" v-else-if="formatLocation(event)">
                                                        <img :src="location" alt="location" class="mr-1" @click.stop="openGoogleMap($event, event)"/>
                                                        <span @click.stop="openGoogleMap($event, event)">{{ formatLocation(event) }}</span>
                                                    </div>

                                                    <div class="follow-unfollow-btns w-100 d-flex justify-content-end">
                                                        <template v-if="!event.showLoader">
                                                            <template v-if="event && event?.event_follower">
                                                                <button class="follow-btn" @click.stop="unFollowEvent(event)">
                                                                    <img :src="follow" alt="follow" />
                                                                </button>
                                                            </template>
                                                            <template v-else>
                                                                <button class="unfollow-btn" @click.stop="followEvent(event)">
                                                                    <img :src="unfollow" alt="unfollow" />
                                                                    <span>Follow</span>
                                                                </button>
                                                            </template>
                                                        </template>

                                                        <!-- Show loader when event.showLoader is true -->
                                                        <div v-if="event.showLoader" class="d-flex justify-content-end">
                                                            <img src="../../../assets/loader.gif" alt="" width="35"/>
                                                        </div>
                                                   </div>
                                                   
                                                </div>
                                            </div>

                                            <div class="event-sec-details" @click="redirectToEvent(event)">
                                                <div class="event-sec-left">
                                                    <!-- Conditionally display association and event type -->
                                                    <span class="asso-tour">
                                                        {{ event.association ? event.association : '' }}
                                                        {{ event.association && event.eventType ? ' - ' : '' }}
                                                        {{ event.eventType }}
                                                    </span>
                                                    <span class="director" v-if="event.director_name">Director: {{ event.director_name }}</span>
                                                    <div v-if="event.eventTeam && event.eventTeam.length > 0">
                                                        <MatesData :members="event.eventTeam" />
                                                    </div>
                                                    <!-- <span v-else class="director">No teams</span> -->

                                                </div>

                                                <div class="event-sec-right">
                                                    <div class="weather-sec" >
                          <WeatherData v-if="isEventWithinFiveDays(event)" :eventId="event.id" />


                          <!-- <div class="weather-content" v-for="(dataItem, index) in event.weatherData.slice(0, 5)"
                            :key="index">
                            <div class="weather-date">
                              <div class="date-part">{{ formatDateWeather(dataItem.day).date }}</div>
                            </div>
                            <img :src="iconUrl + dataItem.icon" alt="Weather icon" />
                            <div class="tempr">
                              <span class="high">
                                {{ Math.round(dataItem.temperatureMax) }}&deg;F
                              </span>
                              <span class="low">
                                {{ Math.round(dataItem.temperatureMin) }}&deg;F
                              </span>
                            </div>
                          </div> -->
                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="eventsData.length" v-observe-visibility="handleScrolledToButtom" >
                                        </div>
                                       <div class="p-3">
                                            <content-placeholders :rounded="true" v-if="showLoader1">
                                                <content-placeholders-heading :img="false" />
                                            </content-placeholders>
                                       </div>
                                    </div>

                                <div class="no-event-wrap" v-if="eventsData.length == 0 && showLoader == 0">
                                    <img src="../../../assets/Svg/no-data.svg" alt />
                                    <p class="header">No events found.</p>
                                    <p class="info">We couldn't find any events. Broaden your search by <br /> tweaking the filters above.</p>
                                </div>
                                <div class="products-list-shim" v-if="eventsData.length == 0 && showLoader == 1">
                                    <content-placeholders :rounded="true">
                                        <content-placeholders-img />
                                        <content-placeholders-heading />
                                    </content-placeholders>
                                    <content-placeholders class="mt-3" :rounded="true">
                                        <content-placeholders-img />
                                        <content-placeholders-heading />
                                    </content-placeholders>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- <div class="live-today">
                        <LiveToday :gameScores="gameScores" :total_game="total_game" :showGameLoader="showGameLoader" />
                    </div> -->
                    <div :class="{'d-none': isApiLoaded && gameScores.length === 0}" class="live-today">
                        <LiveToday :gameScores="gameScores" :total_game="total_game" :showGameLoader="showGameLoader" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
let states = require("../../../assets/js/state");
// import Multiselect from "vue-multiselect";
import Navbar from "@/components/Header/Navbar.vue";
import Banner from "@/components/BannerNotify/BannerPage.vue";
import LeftMenu from "@/components/Home/LeftMenu.vue";
import LeftMenuSide from "@/components/NewGameTime/LeftMenuSide.vue";
import DiscoverEventsFilter from "@/components/NewGameTime/Events/DiscoverEventsFilter.vue";
import LiveToday from "@/components/NewGameTime/LiveToday.vue";
import MatesData from "@/components/NewGameTime/Events/MatesData.vue";
import WeatherData from "@/components/NewGameTime/Events/WeatherData.vue";

export default {
    name: "DiscoverEvents",
    components: {
        Navbar,
        LeftMenu,
        Banner,
        LeftMenuSide,
        DiscoverEventsFilter,
        LiveToday,
        MatesData,
        WeatherData
    },
    data() {
        const today = new Date();
        const currentMonth = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const currentYear = today.getFullYear();
        return {
            iconUrl: "https:",
            openTabSet: 1,
            location: require("../../../assets/Svg/location.svg"),
            unfollow: require("../../../assets/Svg/unfollow.svg"),
            follow: require("../../../assets/Svg/follow.svg"),
            link: require("../../../assets/images/link.png"),
            showLiveToday: true,
            isApiLoaded: true,
            stateOptions: states,
            associationOptions: [],
            filter: {
                name: "",
                startDate: `${currentMonth}/${currentYear}`, // Set default to current month/year
                association: "",
                region: [],
            },
            page: 1,
            lastPage: 1,
            eventsData: [],
            showFollowLoader: 0,
            showLoader: 0,
            showGameLoader: 0,
            showLoader1: false,
            gameScores:[],
            gameScoresOngoing:[],
            gameScoresFinal:[],
            total_game: '',
            url: this.$groupAvatarStorageURL,
            isScrolled: false,
            user_id: localStorage.getItem("userId"),
        };
    },
   
    methods: {
        formatTimeZone(timeZone) {
            if (timeZone && typeof timeZone === 'string') {
                return timeZone.replace(/\(UTC[^)]+\)\s*/, "").trim(); // Removes text like "(UTC-06:00)"
            }
            return timeZone; // Return the original value if it's not a valid string
        },
        handleScroll() {
            let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
            this.isScrolled = scrollTop > 5;
        },
        formatDateWeather(date) {
            const options = { month: 'short', day: 'numeric' };
            const datePart = new Date(date).toLocaleDateString(undefined, options);
            const yearPart = new Date(date).getFullYear();
            return { date: datePart, year: yearPart };
        },
        formatDateRange(startDate, endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);

            const startDateString = start.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
            });
            const endDateString = end.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
            });
            const startYear = start.getFullYear();
            const endYear = end.getFullYear();

            if (startYear === endYear) {
                return `${startDateString} to ${endDateString}, ${startYear}`;
            } else {
                return `${startDateString}, ${startYear} to ${endDateString}, ${endYear}`;
            }
        },
        isEventWithinFiveDays(event) {
            if (!event?.startDate) return false;
            const eventDate = new Date(event.startDate);
            console.log(eventDate, "kskd");

            const today = new Date();
            today.setHours(0, 0, 0, 0);
            eventDate.setHours(0, 0, 0, 0);

            const diffDays = (eventDate - today) / (1000 * 60 * 60 * 24);

            if (diffDays <= 5 && diffDays >= 0) {
                console.log("Event is within the next 5 days! ✅");
                return true;
            }

            return false;
        },
        openGoogleMap(event, item) {
            event.preventDefault(); // Prevents <router-link> navigation
            event.stopPropagation(); // Stops event bubbling
            console.log(item, "hdhhd")
            const strlatLng = item["lan"] + "," + item["long"];
            const urlSuffix = strlatLng
                ? strlatLng
                : item["address"] + ", " + item["city"] + ", " + item["zipCode"];
            console.log(urlSuffix, " urlSuffix")
            window.open(
                "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
                "_blank"
            );
        },
        formatLocation(event) {
            let location = '';
            
            if (event.city) {
                // Add comma if address exists, else just city
                location += (location ? ', ' : '') + event.city;
            }

            if (event.state) {
                // Add comma if address or city exists, else just state
                location += (location ? ', ' : '') + event.state;
            }

            return location;
        },
        
        redirectToEvent(item) {
            const eventId = item.guid;
            this.$router.push({ name: 'AssociationSingleEventDetail', params: { id: eventId } });
        },
        
        
        showLocation(event) {
            return (
                (event.status === 1 &&
                    event.address !== null &&
                    event.address !== "" &&
                    event.address !== "null") ||
                (event.city !== null && event.city !== "" && event.city !== "null") ||
                (event.state !== null && event.state !== "" && event.state !== "null")
            );
        },
        openInNewTab(url) {
            if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
            }
        },
        getLocation(event) {
            const address =
                event.address && event.address !== "null" ? event.address : "";
            const city = event.city && event.city !== "null" ? event.city : "";
            const state = event.state && event.state !== "null" ? event.state : "";

            return `${address} ${city}, ${state}`.trim();
        },
       
        handleRowClick(event) {
            this.$root.$emit("eventsIdForDetails", event);

            // setTimeout(() => {
            //   this.$root.$emit("eventsIdForDetails", event);
            //   this.$router.push({ name: "AssociationSingleEventDetail", params: { id: event.guid } });
            // }, 500);
        },

        handleScrolledToButtom(isVisible) {
            console.log("hittinh ", this.page , this.lastPage)
            if (this.page >= this.lastPage) {
                return;
            }
            if (isVisible) {
                this.page++;
                this.EventsData();
            }
        },
        async followEvent(event) {

            this.$set(event, 'showLoader', true);

            try {
                const formData = new FormData();
                formData.append("event_id", event.id);
                formData.append("user_id", this.user_id);

                // API Call
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}eventfollower/store`,
                    formData
                );

                if (response.data.statusCode === 200) {
                    const eventFollow = response.data.data;
                    console.log(eventFollow);
                    this.$set(event, 'event_follower', eventFollow);



                    console.log(this.eventsData);
                    this.$set(event, 'showLoader', false);  // Stop the loader after the update
                } else {
                    this.errorMessage = response.data.message || "Failed to follow the event.";
                }
            } catch (error) {
                console.error("API Error:", error);
                this.errorMessage = "Failed to fetch data. Please try again.";
            } finally {
                // Ensure loader state is set to false even if an error occurs
                if (event.showLoader) {
                    this.$set(event, 'showLoader', false);
                }
            }
        },

        async unFollowEvent(followEvent) {

            // Find the event by its followEvent ID
            const event = this.eventsData.find(event => event.event_follower && event.event_follower.id === followEvent.event_follower.id);

            if (event) {
                // Set the loading state for this event
                this.$set(event, 'showLoader', true);  // Ensure the loader is reactive

                try {
                    const formData = new FormData();
                    formData.append("follow_id", followEvent.event_follower.id);  // Use followEvent's follower id

                    // API Call
                    const response = await axios.post(
                        `${process.env.VUE_APP_API_URL}eventfollower/unfollow`,
                        formData
                    );

                    if (response.data.statusCode === 200) {
                        // Remove the event_follower from the event object locally (instantly)
                        this.$set(event, 'event_follower', null);  // Or delete event.event_follower
                    } else {
                        this.errorMessage = response.data.message || "Failed to unfollow the event.";
                    }
                } catch (error) {
                    console.error("API Error:", error);
                    this.errorMessage = "Failed to fetch data. Please try again.";
                } finally {
                    // Remove loading state for this event
                    this.$set(event, 'showLoader', false); // Ensure loader state is set to false reactively
                }
            }
        },


        async EventsData() {
            console.log("Fetching Events...");
            this.showLoader = 1;
            this.showLoader1 = true;

            // Ensure eventStatus has a default value
            if (!this.filter.eventStatus) {
                this.filter.eventStatus = "false"; // Default to upcoming events
            }

            try {
                const formData = new FormData();

                if (this.filter.name) formData.append("name", this.filter.name);
                formData.append("eventStatus", this.filter.eventStatus);
                if (this.filter.association) formData.append("association", this.filter.association);

                // Use selectedYear or current year if not provided
                formData.append("selectedYear", this.filter.selectedYear || new Date().getFullYear());

                // Ensure regions (selectedStates) are handled properly
                if (Array.isArray(this.filter.selectedStates) && this.filter.selectedStates.length > 0) {
                    const selectedStatesFormatted = JSON.stringify(
                        this.filter.selectedStates.map(state => ({
                            name: state.name,
                            abbreviation: state.abbreviation
                        }))
                    );
                    formData.append("regions", selectedStatesFormatted);
                }
                // API Call
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}event/adminCreatedEventListings?page=${this.page}`,
                    formData
                );
                if (response.data.statusCode === 200) {
                    const newEventData = response.data.data.data;
                    //if (this.page === 1) {
                    //    this.eventsDetails = [];
                    //}
                    this.eventsData =  this.eventsData.concat(newEventData);
                    console.log(this.eventsData );
                    console.log(response.data.data.last_page );
                    this.lastPage = response.data.data.last_page;
                } else {
                    this.errorMessage = response.data.message || "Failed to fetch events.";
                }
            } catch (error) {
                console.error("API Error:", error);
                this.errorMessage = "Failed to fetch data. Please try again.";
            } finally {
                this.showLoader = 0;
                this.showLoader1 = false;
            }
        },
        getAssociation() {
            axios
                .get(
                    `${process.env.VUE_APP_API_URL}getAssociations?search=${""}`,
                    this.details,
                    {}
                )
                .then((response) => {
                    this.associationOptions = [];
                    this.associationOptions = response.data.data.associations;

                });
        },
        handleFiltersChanged(filters) {
            console.log("Filters Changed:", filters);

            this.filter = {
                ...this.filter,
                association: filters.association.join(','),
                selectedStates: filters.selectedStates,
                selectedYear: filters.year,
                eventStatus: filters.eventStatus,
            };

            this.page = 1;
            this.eventsData = [];
            this.EventsData();
        },
        getLiveScore() {
            this.showGameLoader = 1;
            this.gameScoresOngoing = [];
            this.gameScoresFinal = [];
            this.isApiLoaded = false; // Set flag to false before API call

            axios
                .post(process.env.VUE_APP_API_URL + "tournaments/game/eventsGameListingeMutlipleTeamsForCard", {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    this.gameScores = res.data.data.listing;
                    this.total_game = res.data.data.total_games;
                    // this.showLoader = 0;

                    if (this.gameScores.length > 0) {
                        this.event_name = this.gameScores[0].event_name;
                        this.count = 0;
                        this.gameScores.forEach((gameScore) => {
                            if (gameScore.game_status != 2) {
                                this.gameScoresOngoing.push(gameScore);
                                this.count++;
                            } else {
                                this.gameScoresFinal.push(gameScore);
                            }
                        });
                    }
                })
                .finally(() => {
                    this.showGameLoader = 0;
                    this.isApiLoaded = true; // Set flag to true after API response
                });
        },
      callBoxViewModal(score) {
        this.$root.$emit("openBoxViewScoresModal", score);
        this.$root.$emit("openBoxViewInningModal", score);
        },
    },
    mounted() {
        this.getAssociation();
        this.EventsData();
        this.getLiveScore();
        this.$root.$on("openTabSetting", (id) => {
            this.openTabSet = id;
        });
        let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", this.handleScroll);
        }
    },
    beforeUnmount() {
        let scrollContainer = document.querySelector('.shop-side');
        if (scrollContainer) {
            scrollContainer.removeEventListener("scroll", this.handleScroll);
        }
    },
}
</script>
<style scoped>
.loc-content{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
</style>