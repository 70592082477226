<template>
  <div class="mates-info">
    <h6 v-if="members.length > 6">+{{ members.length-6 }} Teammates</h6>
    <h6 v-else-if="members.length <= 6"> {{members.length}} Teammates</h6>
    <div class="mates-image-wrap">
      <div
        class="mates-image position-relative"
        v-for="user in members.slice(0, 6)"
        :key="user.id"
      >
        <abbr :title="user?.member?.name">
        <div class="users-notification" v-if="imgLoader == 1">
          <img src="../../../assets/loader.gif" alt="" />
        </div>
        
        <img
            @load="onImageLoad"
            :src="$userProfileStorageURL + user?.member?.id +'/profile_picture.jpg?v='+$time"
            onerror="this.src='https://whoifollow.s3.us-west-2.amazonaws.com/chat/groupAvatar/NwqRnGfkQzHrf5lu1PeB1gwAdiZ8Ge5fSBFUTzLS.svg'"

          alt=""
        />
        </abbr>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  props: {
    members: {
      type: Array,
    },
  },
  data() {
    return {
      imgLoader: 1,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
  },
};
</script>

<style scoped>
.mates-info h6{
  font-size: 14px;
    font-weight: 4 00;
    line-height: 19.07px;
    color: #557491;
}
abbr[data-original-title], abbr[title] {
  cursor: pointer!important;
}
</style>
