var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"filter-cotent"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.closeDropdown('age')),expression:"() => closeDropdown('age')"}],staticClass:"dropdown-wrapper"},[_c('button',{staticClass:"dropdown-button",class:{
        'has-value': _vm.selectedAges.length > 0
      },on:{"click":function($event){return _vm.toggleDropdown('age')}}},[_c('span',[_vm._v(_vm._s(_vm.selectedAges.length >= 1 ? _vm.getAgeName(_vm.selectedAges[0]) : 'Age Group'))]),(_vm.selectedAges.length > 1)?_c('div',{staticClass:"badge-wrapper"},[_c('span',{staticClass:"counter-badge"},[_vm._v("+"+_vm._s(_vm.selectedAges.length - 1))])]):_vm._e(),_c('img',{attrs:{"src":_vm.arrow_down,"alt":"arrow-down"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeDropdown === 'age'),expression:"activeDropdown === 'age'"}],staticClass:"dropdown-menu-content"},_vm._l((_vm.ageOptions),function(age){return _c('div',{key:age.id,staticClass:"dropdown-item",class:{ 'selected': _vm.selectedAges.includes(age.id) },on:{"click":function($event){return _vm.toggleAgeSelection(age)}}},[_c('input',{staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedAges.includes(age.id)}}),_c('span',[_vm._v(" "+_vm._s(age.name)+" ")])])}),0)]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.closeDropdown('team')),expression:"() => closeDropdown('team')"}],staticClass:"dropdown-wrapper"},[_c('button',{staticClass:"dropdown-button",class:{
        'has-value': _vm.selectedTeams.length > 0
      },on:{"click":function($event){return _vm.toggleDropdown('team')}}},[_c('span',[_vm._v(_vm._s(_vm.selectedTeams.length >= 1 ? _vm.getRatingName(_vm.selectedTeams[0]) : 'Rating'))]),(_vm.selectedTeams.length > 1)?_c('div',{staticClass:"badge-wrapper"},[_c('span',{staticClass:"counter-badge"},[_vm._v("+"+_vm._s(_vm.selectedTeams.length - 1))])]):_vm._e(),_c('img',{attrs:{"src":_vm.arrow_down,"alt":"arrow-down"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeDropdown === 'team'),expression:"activeDropdown === 'team'"}],staticClass:"dropdown-menu-content"},_vm._l((_vm.ratingOptions),function(team){return _c('div',{key:team.id,staticClass:"dropdown-item",class:{ 'selected': _vm.selectedTeams.includes(team.id) },on:{"click":function($event){return _vm.toggleTeamSelection(team)}}},[_c('input',{staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedTeams.includes(team.id)}}),_c('span',[_vm._v(" "+_vm._s(team?.rate)+" ")])])}),0)]),_c('div',{staticClass:"dropdown-wrapper"},[_c('button',{staticClass:"dropdown-button",class:{
        'has-value': _vm.selectedGender
      },on:{"click":function($event){return _vm.toggleDropdown('gender')}}},[_c('span',[_vm._v(_vm._s(_vm.selectedGender || 'Gender'))]),_c('img',{attrs:{"src":_vm.arrow_down,"alt":"arrow-down"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeDropdown === 'gender'),expression:"activeDropdown === 'gender'"}],staticClass:"dropdown-menu-content"},_vm._l((_vm.genders),function(gender){return _c('div',{key:gender,staticClass:"dropdown-item",on:{"click":function($event){return _vm.selectGender(gender)}}},[_c('span',[_vm._v(_vm._s(gender))])])}),0)]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.closeDropdown('state')),expression:"() => closeDropdown('state')"}],staticClass:"dropdown-wrapper"},[_c('button',{staticClass:"dropdown-button",class:{ 'has-value': _vm.selectedStates.length > 0 },on:{"click":function($event){return _vm.toggleDropdown('state')}}},[_c('span',[_vm._v(_vm._s(_vm.selectedStates.length >= 1 ? _vm.selectedStates[0] : 'State'))]),(_vm.selectedStates.length > 1)?_c('div',{staticClass:"badge-wrapper"},[_c('span',{staticClass:"counter-badge"},[_vm._v("+"+_vm._s(_vm.selectedStates.length - 1))])]):_vm._e(),_c('img',{attrs:{"src":_vm.arrow_down,"alt":"arrow-down"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeDropdown === 'state'),expression:"activeDropdown === 'state'"}],staticClass:"dropdown-menu-content"},_vm._l((_vm.stateOptions),function(state){return _c('div',{key:state.id,staticClass:"dropdown-item",class:{ 'selected': _vm.selectedStates.includes(state.name) },on:{"click":function($event){return _vm.toggleStateSelection(state)}}},[_c('input',{staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedStates.includes(state.name)}}),_c('span',[_vm._v(_vm._s(state.name))])])}),0)])]),(_vm.isResetVisible)?_c('button',{staticClass:"reset-button",on:{"click":_vm.resetFilters}},[_vm._v("Reset Filters")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }