<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3 pb-3">
        <div class="row no-gutters">
          <div class="col-sm-12 col-md-7 col-lg-8 px-0">
            <div class="order-left-side">
              <CheckoutHeading />
              <div class="order-wrapper">
                <div class="order-info">
                  <div class="order-heading">
                    <img src="../assets/images/card.png" alt />
                    <h6>How would you like to pay ?</h6>
                  </div>
                  <div class="order-form">
                    <div
                      class="account-wrapper-content my-0 px-0"
                      style="border-radius: 0; box-shadow: none"
                      id="accordionExample"
                    >
                      <div class="account-content-info pt-0 d-block">
                        <div
                          id="headingOne"
                          data-toggle="collapse"
                          data-target="#collapseOe"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <div>
                            <h6 class="info-header mb-0">
                              <button class="btn pl-0 text-left" type="button">Add New Card</button>
                            </h6>
                          </div>
                          <!-- <div>
                            <img
                              src="../assets/images/arrow-right.png"
                              alt=""
                              class="fa-chevron-right"
                            />
                            <img
                              src="../assets/images/arrow-down.png"
                              alt=""
                              class="fa-chevron-down"
                            />
                          </div>-->
                        </div>

                        <div
                          id="collapseOne"
                          class="in"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body p-0 d-flex flex-column">
                            <form onsubmit="return false;" id="form1" class="m-0">
                              <!--      Make your own form or copy this one -->
                              <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                  <label for="first_name">First Name</label>
                                  <input
                                    name="cardholder-first-name"
                                    class="field input-box"
                                    placeholder="First Name"
                                    v-model="firstname"
                                  />
                                </div>
                                <div class="form-group col-12 col-md-6">
                                  <label for="last_name">Last Name</label>
                                  <input
                                    name="cardholder-last-name"
                                    class="field input-box"
                                    placeholder="Last Name"
                                    v-model="lastname"
                                  />
                                </div>
                              </div>
                              <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                  <label for="card_no">Card No</label>
                                  <div
                                    class="last-fields"
                                    id="staxjs-number"
                                    style="
                                      height: 50px;
                                      padding-top: 15px;
                                      border-radius: 5px;
                                      border: 1px solid #ced4da;
                                      padding-left: 10px !important;
                                    "
                                  ></div>
                                </div>
                                <div class="form-group col-12 col-md-2">
                                  <label for="cvv">Cvv</label>
                                  <div
                                    class="last-fields"
                                    id="staxjs-cvv"
                                    style="
                                      height: 50px;
                                      padding-top: 15px;
                                      padding-left: 10px !important;
                                      border-radius: 5px;
                                      border: 1px solid #ced4da;
                                    "
                                  ></div>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                  <label for="expiry">Expiry Date</label>
                                  <div
                                    class="card-element last-fields"
                                    style="
                                      height: 50px;
                                      display: flex;
                                      align-items: center;
                                      justify-content: center;
                                      border-radius: 5px;
                                      border: 1px solid #ced4da !important;
                                    "
                                  >
                                    <input
                                      name="month"
                                      size="3"
                                      maxlength="2"
                                      placeholder="MM"
                                      v-model="month"
                                      style="
                                        padding: 0 !important;
                                        padding-left: 10px !important;
                                        border: 1px solid #ced4da;
                                        border-right: none !important;
                                        border-left: none !important;
                                        border-radius: 0 !important;
                                        margin-bottom: 0 !important;
                                        width: 45px;
                                      "
                                    />
                                    <i>/</i>
                                    <input
                                      name="year"
                                      size="5"
                                      maxlength="4"
                                      placeholder="YYYY"
                                      v-model="year"
                                      style="
                                        padding: 0 !important;
                                        padding-left: 10px !important;
                                        border: 1px solid #ced4da;
                                        border-right: none !important;
                                        border-left: none !important;
                                        border-radius: 0 !important;
                                        margin-bottom: 0 !important;
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                            <!-- <div class="d-flex align-items-center mt-2">
                              <span>Save this card for future use</span>
                              <toggle-button
                                class="toogle m-0 ml-2"
                                :value="false"
                                :sync="true"
                                :width="34"
                                v-model="saveCard"
                                @change="saveCardNotify"
                              />
                            </div>-->
                          </div>
                        </div>
                      </div>
                      <!-- <div class="account-content-info d-block">
                        <div
                          id="headingTwo"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <div>
                            <h6 class="info-header mb-0">
                              <button class="btn pl-0 text-left collapsed" type="button">
                                Pay from existing card
                              </button>
                            </h6>
                          </div>
                          <div>
                            <img
                              src="../assets/images/arrow-right.png"
                              alt=""
                              class="fa-chevron-right"
                            />
                            <img
                              src="../assets/images/arrow-down.png"
                              alt=""
                              class="fa-chevron-down"
                            />
                          </div>
                        </div>
                        <div
                          id="collapseTwo"
                          class="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body p-0 pt-3 d-flex flex-column"></div>
                        </div>
                      </div>-->
                    </div>
                  </div>
                </div>
                <PaymentMethod />
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-5 col-lg-4 px-0">
            <div class="order-item-history">
              <OrderItemHistory :shopConfigData="shopConfigData" />
              <button id="paybutton" type="submit" class="order-btn order-btn-disabled">Place Order</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://staxjs.staxpayments.com/stax.js?nocache=2"></script>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import CheckoutHeading from "../components/Shop/Order/CheckoutHeading.vue";
import PaymentMethod from "../components/Shop/Payment/PaymentMethod.vue";
import OrderItemHistory from "../components/Shop/Order/OrderItemHistory.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import axios from "axios";

export default {
  components: {
    Navbar,
    LeftMenu,
    CheckoutHeading,
    PaymentMethod,
    OrderItemHistory,
    Banner
  },
  data() {
    return {
      promo: "",
      discountedPercentage: 0,
      errorMessage: "",
      showLoader: 0,
      total: 26,
      firstname: "",
      lastname: "",
      month: "",
      year: "",
      saveCard: false,
      address_1: "100 S Orange Ave",
      address_2: "Suite 400",
      address_city: "Orlando",
      address_state: "FL",
      address_zip: "32811",
      address_country: "USA",
      send_receipt: false,
      validate: false,
      StaxPayKey: this.$staxPayKey,
      product_ids: "",
      unAvailable: false,
      meta: {
        reference: "invoice-reference-num", // optional - will show up in emailed receipts
        memo: "notes about this transaction", // optional - will show up in emailed receipts
        otherField1: "other-value-1", // optional - we don't care
        otherField2: "other-value-2", // optional - we don't care
        subtotal: 0, // optional - will show up in emailed receipts
        tax: 0, // optional - will show up in emailed receipts, and used for L2 processing. Accepts the tax in dollar amount. To qualify for L2 processing rates, the tax dollar amount must be equivalent to 0.1% and 30% of the transaction's total.
        poNumber: "7649", // customer code used for L2 processing.
        shippingAmount: 0, // the shipping amount for the transaction used for L2 processing
        lineItems: [
          // optional - will show up in emailed receipts
          {
            id: "optional-fm-catalog-item-id",
            item: "Demo Item",
            details: "this is a regular demo item",
            quantity: 0,
            price: 0
          }
        ],
        invoice_merchant_custom_fields: [
          {
            id: "dc4b-6c74-00dd-fab1-fe00", // Required, must be a unique string.
            name: "External ID", // The name of the custom field that will be displayed to your customers and users; this will appear above the field as a label.
            placeholder: "Ex. #123", // The placeholder for the field; this is the faint text that will appear in the entry box of your custom field to help guide your users before they enter in the value when creating an Invoice.
            required: true, // Determines if this field is required to be filled by the user (not customer) when first creating an Invoice.
            type: "text", // Input type. Only 'text' is supported.
            value: "123456789", // The value that will appear when viewing the Invoice or Invoice Email. For the merchant, this will also appear when viewing the Invoice via the Invoices or Edit Invoice pages.
            visible: true // This determines if the field is visible when viewing the Invoice or Invoice Email. If false, will only appear in merchant-facing pages such as the Invoices or Edit Invoice pages.
          }
        ]
      }
    };
  },
  name: "Payment",
  computed: {
    ...mapState("cart", ["cart"]),
    ...mapGetters("cart", ["cartTotalPrice"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("cart", ["cartItemCount"]),
    ...mapGetters("cart", ["shopConfigData"])
  },
  methods: {
    ...mapActions({
      clearCartItems: "cart/clearCartItems"
    }),

    // async checkAvail() {
    //   let array = [];
    //   for (let i = 0; i < this.cart.length; i++) {
    //     array.push(this.cart[i].product.products.id);
    //   }
    //   let formData = new FormData();
    //   formData.append("products", array);
    //   await axios
    //     .post("/shop/productAvailability", formData, {})
    //     .then(response => {
    //       if (response.data.statusCode == 200) {
    //         if (response.data.optional == 2) {
    //           this.flashMessage.error({
    //             title: "Error",
    //             message: response.data.message,
    //             time: 5000,
    //             blockClass: "custom-block-class"
    //           });
    //         }
    //       } else {
    //         this.errorMessage = response.data.message;
    //       }
    //     });
    // },

    saveCardNotify: function() {
      if (this.saveCard == true) {
        this.saveCard = false;
      } else {
        this.saveCard = true;
      }
    }
  },
  mounted() {
    if (this.cartItemCount <= 0) {
      this.$router.push({ name: "Shop" });
    }
    var payButton = document.querySelector("#paybutton");
    $("#gridCheck").click(function() {
      
      //check if checkbox is checked
      if ($(this).is(":checked") && this.firstname != "" || this.firstname != null && this.lastname != "" || this.lastname != null && this.year != "" || this.year != null && this.month != "" || this.month != null){
        $("#paybutton").removeAttr("disabled"); //enable input
        $("#paybutton").removeClass("order-btn-disabled");
      } else {
        $("#paybutton").attr("disabled", true); //disable input
        $("#paybutton").addClass("order-btn-disabled");
      }
    });
    // Init StaxJs SDK
    var staxJs = new StaxJs(this.StaxPayKey, {
      number: {
        id: "staxjs-number",
        placeholder: "0000 0000 0000 0000"
      },
      cvv: {
        id: "staxjs-cvv",
        placeholder: "000"
      }
    });

    // tell staxJs to load in the card fields
    staxJs
      .showCardForm()
      .then(handler => {
        // for testing!
        handler.setTestPan("");
        handler.setTestCvv("");
        var form = document.querySelector("#form1");
        form.querySelector("input[name=month]").value = "";
        form.querySelector("input[name=year]").value = "";
        form.querySelector("input[name=cardholder-first-name]").value;
        form.querySelector("input[name=cardholder-last-name]").value;
        // form.querySelector("input[name=phone]").value = "";
      })
      .catch(err => {
        console.log("error init form " + err);
        // reinit form
      });

    staxJs.on("card_form_complete", message => {
      // activate pay button
      payButton.disabled = false;
      if (document.getElementById("gridCheck").checked) {
        $("#paybutton").removeAttr("disabled"); //enable input
        $("#paybutton").removeClass("order-btn-disabled");
      } else {
        $("#paybutton").attr("disabled", true); //disable input
        $("#paybutton").addClass("order-btn-disabled");
      }
    });

    staxJs.on("card_form_incomplete", message => {
      // deactivate pay button
      payButton.disabled = true;
      $("#paybutton").addClass("order-btn-disabled");
    });

    document.querySelector("#paybutton").onclick = () => {
      var details = localStorage.getItem("shipping");
      var detailget = JSON.parse(details);
      payButton.disabled = true;
      let loader = this.$loading.show({
        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });
      var form = document.querySelector("#form1");
      const getRandomId = (min = 1000, max = 500000) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        const num = Math.floor(Math.random() * (max - min + 1)) + min;
        return num;
      };
      let Data = [];
      for (let i = 0; i < this.cart.length; i++) {
        let x = {
          id: this.cart[i].product.products.id,
          item: this.cart[i].product.products.name,
          details: this.cart[i].product.products.product_description,
          quantity: this.cart[i].quantity,
          price: this.cart[i].product.products.price
        };
        Data.push(x);
      }
      var totalPayable =
      (this.cartTotalPrice || 0) -
      ((this.cartTotalPrice || 0) / 100) * (this.discountedPercentage || 0) +
      ((this.cartTotalPrice || 0) / 100) * (this.shopConfigData.tax_percentage || 0) +
      ((this.cartTotalPrice || 0) / 100) * (this.shopConfigData.shipping_cost || 0);

    var totalPayableBefore =
      (this.cartTotalPrice || 0) +
      ((this.cartTotalPrice || 0) / 100) * (this.shopConfigData.tax_percentage || 0) +
      ((this.cartTotalPrice || 0) / 100) * (this.shopConfigData.shipping_cost || 0);

    // Ensure that totalPayable is a valid number before using it
    if (!isNaN(totalPayable)) {
      var extraDetails = {
        total: totalPayable.toFixed(2), // 1$
        firstname: form.querySelector("input[name=cardholder-first-name]").value,
        lastname: form.querySelector("input[name=cardholder-last-name]").value,
        company: "WHIIFOLLOW",
        email: this.user.email,
        month: form.querySelector("input[name=month]").value,
        year: form.querySelector("input[name=year]").value,
        phone: detailget.mob,
        address_1: detailget.address,
        address_2: "",
        address_city: detailget.city,
        address_state: detailget.state,
        address_zip: detailget.zip_code,
        address_country: detailget.country,
        url: "https://app.staxpayments.com/#/bill/",
        method: "card",
        validate: false,
        meta: {
          reference: getRandomId(), // optional - will show up in emailed receipts
          memo: "This is user invoice", // optional - will show up in emailed receipts
          subtotal: totalPayable, // optional - will show up in emailed receipts
          tax: this.shopConfigData.tax_percentage, // optional - will show up in emailed receipts
          lineItems: Data
        }
      };
    } else {
      console.error("Total payable is NaN due to invalid input values");
    };

      let newCartCheck =[];
      for (let i = 0; i < this.cart.length; i++) {
        let x = {
          product_id: this.cart[i].product.products.id,
          quantity: this.cart[i].quantity,
          size: this.cart[i].size?.description
        };
        newCartCheck.push(x);
      }
      axios.post("/shop/productAvailability", newCartCheck, {}).then(response => {
        if (response.data.statusCode == 200) {
          if (response.data.optional == 2) {
            loader.hide();
            this.unAvailable = true;
            this.flashMessage.error({
              title: "Error",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class"
            });
            $("#paybutton").removeAttr("disabled");
          }
        } else {
          this.errorMessage = response.data.message;
        }
      });

      // call pay api
      if (!this.unAvailable) {
        staxJs
          .pay(extraDetails)
          .then(result => {
            if (result.id && !this.unAvailable) {
              this.flashMessage.success({
                title: "Success",
                message: "Order Placed Successfully",
                time: 5000,
                blockClass: "custom-block-class"
              });

              // Prepare the cart details
              let newCart = [];
              for (let i = 0; i < this.cart.length; i++) {
                let x = {
                  product_id: this.cart[i].product.products.id,
                  quantity: this.cart[i].quantity,
                  size: this.cart[i].size?.description
                };
                newCart.push(x);
              }

              // Prepare result object with necessary calculations
              result["cart"] = newCart;
              result["billingdata"] = detailget;
              result["reference"] = result.meta.reference;

              // Ensure cartTotalPrice is a valid number before applying calculations
              const cartTotalPrice = this.cartTotalPrice || 0;
              const shippingCost = this.shopConfigData.shipping_cost || 0;
              const taxPercentage = this.shopConfigData.tax_percentage || 0;
              const discountedPercentage = this.discountedPercentage || 0;
              const totalPayable = this.totalPayable || 0;

              // Calculate totals
              result["total"] = cartTotalPrice.toFixed(2);
              result["tax_percentage"] = taxPercentage;
              result["shipping_percentage"] = shippingCost;
              result["shipping_amount"] = ((cartTotalPrice / 100) * shippingCost).toFixed(2);
              result["grand_total"] = totalPayable.toFixed(2);
              result["no_of_products"] = this.cart.length; // Use length of cart for product count
              result["discounted_subtotal"] = ((cartTotalPrice / 100) * discountedPercentage).toFixed(2);
              result["discount"] = discountedPercentage;

              // Ensure `card_number` is safely accessed
              result["card_number"] = result.child_transactions?.[0]?.last_four || null;

              // Calculate tax amount
              result["tax_amount"] = ((cartTotalPrice / 100) * taxPercentage).toFixed(2);

              // Add promo ID if available
              result["promo_id"] = this.promo?.id || null;

              // Send to API
              return axios
                .post(process.env.VUE_APP_API_URL + "charge", result, {})
                .then(response => {
                  if (response.data.statusCode == 200) {
                    this.clearCartItems();
                    this.$router.push({ name: "Thanks" });
                  } else {
                    this.flashMessage.error({
                      title: "Error",
                      message: response.data.message,
                      time: 5000,
                      blockClass: "custom-block-class"
                    });
                    payButton.disabled = false;
                  }
                  this.showLoader = 0;
                  loader.hide();
                });
            }
          }).catch(err => {
            payButton.disabled = false;
            // if a transaction did occur, but errored, the error will be in the message of the first child transactoin
            if (err.message) {
              this.flashMessage.error({
                title: "Error",
                message: err.message,
                time: 5000,
                blockClass: "custom-block-class"
              });
            } else if (err.payment_attempt_message) {
              this.flashMessage.error({
                title: "Error",
                message: err.payment_attempt_message,
                time: 5000,
                blockClass: "custom-block-class"
              });
            } else {
              // else, there may have been a validation error - and tokenization failed
              // err can contain an object where each key is a field name that points to an array of errors
              // such as {mobile_number: ['The mobile number is invalid']}
              if (err.total == "The total must be at least .01.") {
                this.flashMessage.error({
                  title: "Error",
                  message: "Cart is Empty!",
                  time: 5000,
                  blockClass: "custom-block-class"
                });
              } else if (
                err.phone ==
                "The mobile field is required when customer id is not present."
              ) {
                this.flashMessage.error({
                  title: "Error",
                  message: "Mobile Number is Required!",
                  time: 5000,
                  blockClass: "custom-block-class"
                });
              } else if (err.status == 0) {
                this.flashMessage.error({
                  title: "Error",
                  message:
                    "Please Check Your Card Information and Try Again or Contact Support",
                  time: 5000,
                  blockClass: "custom-block-class"
                });
              } else {
                this.flashMessage.error({
                  title: "Error",
                  message:
                    typeof err === "object"
                      ? Object.keys(err)
                          .map(k => err[k].join(" "))
                          .join(" ")
                      : JSON.stringify(err),
                  time: 5000,
                  blockClass: "custom-block-class"
                });
              }
            }
            loader.hide();
            this.unAvailable = false;
          });
      }
    };

    this.$root.$on("getDiscountFromPromo", (percentage, promo) => {
      this.discountedPercentage = percentage;
      this.promo = promo;
    });
  }
};
</script>
<style scoped>
.group {
  border-radius: 5px;
}

.field {
  background: transparent;
  font-weight: 300;
  color: #31325f;
  outline: none;
  flex: 1;
  padding-right: 15px;
  padding-left: 15px;
  cursor: text;
  height: 45px;
}

.field::-webkit-input-placeholder {
  color: #cfd7e0;
}

.field::-moz-placeholder {
  color: #cfd7e0;
}

.outcome {
  margin-top: 15px;
  width: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input-box {
  border-radius: 5px;
  border: 1px solid #8898aa;
}
form {
  margin: 20px 0;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
#card-element {
  font-size: 16px;
  flex-grow: 1;
}
.validity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-top: -4px;
}

@media screen and (max-width: 955px) and (min-width: 320px) {
  label > span {
    margin-right: 0;
  }
  .field {
    margin-top: 10px;
    padding: 12px 10px;
  }
  .last-fields {
    margin-top: 10px;
  }
}
</style>
