<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#ServiceRoasterModal"
      ref="openModalService"
    ></button>
    <div
      class="modal fade"
      id="ServiceRoasterModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Event Travel Arrangements
            </h5>
            <button
              type="button"
              class="close text-primary"
              data-dismiss="modal"
              aria-label="Close"
              ref="openModalServiceClose"
              @click="resetForm()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="toggle-content">
              <div>
                <h6 class="toogle-header" v-if="user_data && user_data.username">Did {{ user_data.username }} need travel arrangements?</h6>
                <h6 class="toogle-header" v-else>Do you need travel arrangements?</h6>
               
                <span class="toggle-text" v-if="user_data && user_data.username && travelStatus == true"
                  >Please select the services for {{ user_data.username }} need while attending the
                  event</span>

                  <span class="toggle-text" v-else-if="travelStatus == true"
                  >Please select the services you need while attending the
                  event</span>
                
              </div>
              <toggle-button
                class="toggle"
                v-model="travelStatus"
                @change="getDivs"
                :value="false"
                :sync="true"
                :width="34"
                ref="toggleBtn"
              />
            </div>

            <div v-if="travelStatus == true">
              <div id="accordion">
                <div v-for="(select, index) in selections" :key="index">
                  <div :id="'heading' + index">
                    <h6
                      class="py-2 border-bottom mb-3 d-flex align-items-center justify-content-between"
                      data-toggle="collapse"
                      :data-target="'#collapse' + index"
                      aria-expanded="true"
                      :aria-controls="'collapse' + index"
                    >
                      <span>
                        {{ select.text }}
                      </span>
                      <toggle-button
                        class="custom-control-toggle"
                        :id="'customCheck' + select.value"
                        @change="getValue(select, $event)"
                        :width="34"
                        :value="false"
                      />
                    </h6>
                  </div>

                  <div v-if="select.value === 'hotel'">
                    <div
                      :id="'collapse' + index"
                      class="collapse"
                      aria-labelledby="'heading' + index"
                      data-parent="#accordion"
                    >
                      <div class="row no-gutters">
                        <div class="col-12 col-sm-6 pr-sm-3">
                          <div class="form-group control-group">
                            <label for="startDateCheck">Start Date</label>
                            <span
                              class="label-type error event-error ml-2"
                              v-if="startDateError != ''"
                            >
                              Please select Start Date.
                            </span>
                            <div
                              class="input-group date"
                              id="startDateCheck"
                              data-target-input="nearest"
                            >
                              <input
                                class="form-control"
                                required="required"
                                id="startDate"
                                type="date"
                                v-model="startDate"
                                v-on:change="checkdate"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6 mt-2 mt-sm-0">
                          <div class="form-group">
                            <label for="endDateCheck">End Date</label>
                            <span
                              class="label-type error event-error ml-2"
                              v-if="endDateError != ''"
                            >
                              Please select End Date.
                            </span>
                            <span
                              class="label-type error event-error ml-2"
                              v-if="endDateErrorGreater != ''"
                            >
                              End Date should be greater than start date
                            </span>
                            <div
                              class="input-group date"
                              id="endDateCheck"
                              data-target-input="nearest"
                            >
                              <input
                                class="form-control"
                                required="required"
                                id="endDate"
                                type="date"
                                v-model="endDate"
                                v-on:change="checkdate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-sm-6 mt-3">
                          <div class="product-actions">
                            <p>Adults</p>
                            <div class="product-inc-dec">
                              <div
                                class="product-decrement"
                                @click="adultdecrement()"
                              >
                                <img
                                  src="../../../../assets/images/minus.png"
                                  alt=""
                                />
                              </div>
                              <div class="product-value">
                                <span>{{ adultQuantity }}</span>
                              </div>
                              <div
                                class="product-increment"
                                @click="adultIncrement()"
                              >
                                <img
                                  src="../../../../assets/images/plus.png"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6 mt-3 mb-2">
                          <div class="product-actions">
                            <p>Rooms</p>
                            <div class="product-inc-dec">
                              <div
                                class="product-decrement"
                                @click="roomdecrement()"
                              >
                                <img
                                  src="../../../../assets/images/minus.png"
                                  alt=""
                                />
                              </div>
                              <div class="product-value">
                                <span>{{ room_count }}</span>
                              </div>
                              <div
                                class="product-increment"
                                @click="roomIncrement()"
                              >
                                <img
                                  src="../../../../assets/images/plus.png"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 mt-2 notes-div">
                          <div class="form-group">
                            <label for="note">Notes</label>
                            <textarea
                              type="text"
                              class="form-control pt-3 add-notes"
                              id="note"
                              rows="4"
                              :class="{
                                'is-invalid': validationStatus($v.note),
                              }"
                              v-model.trim="$v.note.$model"
                            ></textarea>
                            <div
                              v-if="!$v.note.required && validationserror"
                              class="invalid-tooltip"
                            >
                              Notes is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer"> -->
          <div class="event-btns-wrapper justify-content-end p-3">
            <button
              type="button"
              v-if="this.isDisabledServices == false"
              id="servicesBtn"
              class="btn btn-primary w-25"
              block
              @click="getServices(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>

            <button
              type="button"
              v-if="this.isDisabledServices == true"
              id="servicesBtn"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import $ from "jquery";
export default {
  name: "servicesModal",
  props: {
    users: {
      type: Array,
    },
    singleEvent: Object,
  },
  data() {
    return {
      selections: [
        { value: "hotel", text: "Hotel" },
        { value: "bnb", text: "Bnb" },
        { value: "car_rental", text: "Car Rental" },
        { value: "airline_tickets", text: "Airline Tickets" },
      ],
      selectedArray: [],
      isDisabledServices: true,
      isLoadingArray: [],
      showRoomInput: false,
      travelStatus: false,
      exactRoomStartDate: "",
      exactRoomEndDate: "",
      startDate: "",
      endDate: "",
      startDateError: "",
      endDateError: "",
      endDateErrorGreater: "",
      exactStartDateformat: "",
      exactEndDateformat: "",
      room_count: 1,
      adultQuantity: 1,
      note: "",
      validationserror: false,
      user_id: "",
      user_data: [],
    };
  },
  validations: {
    startDate: { required },
    endDate: { required },
    note: { required },
  },
  methods: {
    resetForm: function () {
      this.travelStatus = false;
      this.note = ""; 
      this.validationserror = false;
      this.$v.$reset();
      this.validationserror = false;
      this.endDateError = "";
      this.startDateError = "";
    },
    // Check dates
    checkdate: function () {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Start Date
      if (this.startDate.length == 10) {
        const startDateArray = this.startDate.split("-");
        var startMonth = startDateArray[1];
        var startDay = startDateArray[2];
        var startYear = startDateArray[0];

        var varDate = startMonth + "-" + startDay + "-" + startYear; //dd-mm-YYYY

        var startDateConvert = new Date(varDate);
        this.exactStartDateformat = startDateConvert;
        this.exactRoomStartDate = month[startMonth - 1] + " " + startDay + ". " + startYear;
        if (this.startDateConvert == "") {
          this.startDateError = "on";
        } else {
          this.startDateError = "";
        }
      }

      // End Date-----
      if (this.endDate.length == 10) {
        const endDateArray = this.endDate.split("-");
        var endMonth = endDateArray[1];
        var endDay = endDateArray[2];
        var endYear = endDateArray[0];

        var varDatee = endMonth + "-" + endDay + "-" + endYear; //dd-mm-YYYY
        var endDateConvert = new Date(varDatee);
        if (endDateConvert < startDateConvert) {
          this.endDateError = "";
          this.endDateErrorGreater = "on";
          this.exactRoomEndDate = "";
          this.isDisabledServices = true;
        } else {
          this.exactEndDateformat = endDateConvert;
          this.endDateError = "";
          this.endDateErrorGreater = "";
          this.isDisabledServices = false;
          this.exactRoomEndDate =
            month[endMonth - 1] + " " + endDay + ". " + endYear;
        }
      }
    },
    roomIncrement() {
      this.room_count = this.room_count + 1;
    },
    roomdecrement() {
      var newQuantity = this.room_count - 1;

      if (newQuantity > 0) {
        this.room_count = this.room_count - 1;
      }
    },
    adultIncrement() {
      this.adultQuantity = this.adultQuantity + 1;
    },
    adultdecrement() {
      var newQuantity = this.adultQuantity - 1;

      if (newQuantity > 0) {
        this.adultQuantity = this.adultQuantity - 1;
      }
    },
    getDivs() {
      const onlyHotelSelected =
        this.selectedArray.length === 1 && this.selectedArray[0] === "Hotel";

      if (onlyHotelSelected) {
        this.showRoomInput = true;
      } else {
        this.showRoomInput = false;
      }
      if (this.travelStatus == false) {
        this.selectedArray = [];
        this.showRoomInput = false;
        this.room_count = 1;
        this.travelStatus = false;
        this.adultQuantity = 1;
        this.note = "";
        this.exactStartDateformat = "";
        $("#servicesBtn").prop("disabled", false);
        this.isDisabledServices = false;
      }
      if (this.selectedArray.length == 0 && this.travelStatus == true) {
        $("#servicesBtn").prop("disabled", true);
        this.isDisabledServices = true;
      } else {
        $("#servicesBtn").prop("disabled", false);
        this.isDisabledServices = false;
      }
    },
    getValue(select, event) {
      if (event.value === true) {
        if (select.value === "hotel") {
          this.showRoomInput = true;
        }
        this.selectedArray.push(select.text);
      } else {
        if (select.value === "hotel") {
          this.showRoomInput = false;
        }
        const index = this.selectedArray.indexOf(select.text);
        if (index > -1) {
          this.selectedArray.splice(index, 1);
        }
        // this.selectedArray.splice(this.selectedArray.indexOf(select.text), 1);
        // this.selectedArray = [];
        this.room_count = 1;
        // this.travelStatus = false;
        this.adultQuantity = 1;
        this.note = "";
        this.exactStartDateformat = "";
      }
      // Check if any selection toggle is true
      let anySelectionTrue = this.selections.some((item) => {
        return this.selectedArray.includes(item.text);
      });

      // Set travelStatus based on the anySelectionTrue flag
      this.travelStatus = anySelectionTrue;
      if (this.selectedArray.length == 0) {
        $("#servicesBtn").prop("disabled", true);
        this.isDisabledServices = true;
      } else {
        $("#servicesBtn").prop("disabled", false);
        this.isDisabledServices = false;
      }
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    getServices(index) {
      if (this.travelStatus == true) {
        if (this.startDate == "") {
          this.startDateError = "on";
        } else {
          this.startDateError = "";
        }
        if (this.endDate == "") {
          this.endDateError = "on";
        } else {
          this.endDateError = "";
        }
        if (this.selectedArray.length == "") {
          $("#servicesBtn").prop("disabled", true);
          this.isDisabledServices = true;
          // this.$set(this.isLoadingArray, index, true);
        } else {
          $("#servicesBtn").prop("disabled", false);
          this.isDisabledServices = false;
          this.$set(this.isLoadingArray, index, false);
          if (this.showRoomInput == true) {
            this.validationserror = true;
            this.$v.$touch();
            if (this.$v.$pendding || this.$v.$error) return;
            // var list = this.selectedArray;
            // for (let i = 0; i < list.length; i++) {
            //   if (list[i] === "Hotel") {
            //     var text =
            //       list[i] +
            //       "(" +
            //       this.exactRoomStartDate +
            //       " - " +
            //       this.exactRoomEndDate +
            //       ")" +
            //       " Room " +
            //       this.room_count +
            //       "x " +
            //       "Adults " +
            //       this.adultQuantity +
            //       "x";
            //     list[i] = text;
            //   }
            // }

            this.$root.$emit(
              "dataForservice",
              this.selectedArray,
              this.user_id,
              this.room_count,
              this.adultQuantity,
              this.note,
              this.exactRoomStartDate,
              this.exactRoomEndDate
            );
            this.$root.$emit(
              "attendeesServiceForMyEvent",
              this.selectedArray,
              this.room_count,
              this.adultQuantity,
              this.note,
              this.exactRoomStartDate,
              this.exactRoomEndDate
            );
            this.$root.$emit("attendeesData", this.selectedArray,
              this.user_id,
              this.room_count,
              this.adultQuantity,
              this.note,
              this.exactRoomStartDate,
              this.exactRoomEndDate);
          } else {
            this.$root.$emit(
              "dataForservice",
              this.selectedArray,
              this.user_id,
              "",
              "",
              "",
              "",
              ""
            );
            this.$root.$emit(
              "attendeesServiceForMyEvent",
              this.selectedArray,
              "",
              "",
              "",
              "",
              ""
            );
            this.$root.$emit("attendeesData", this.selectedArray,
              this.user_id,
              "",
              "",
              "",
              "",
              "",
            )
          }
        }
      } else {
        this.$root.$emit("attendeesServiceForMyEvent", "", "", "", "", "", "");
        this.$root.$emit("dataForservice", "", this.user_id, "", "", "", "", "");
        this.$root.$emit("attendeesData",
              "",
              this.user_id,
              "",
              "",
              "",
              "",
              ""
            )
      }
      var elem = this.$refs.openModalServiceClose;
      if (elem) {
        elem.click();
        this.selectedArray = [];
        this.room_count = 1;
        this.travelStatus = false;
        this.adultQuantity = 1;
        this.note = "";
        this.exactStartDateformat = "";
        this.startDate = "";
        this.endDate = "";
        this.selections = [
          { value: "hotel", text: "Hotel" },
          { value: "bnb", text: "Bnb" },
          { value: "car_rental", text: "Car Rental" },
          { value: "airline_tickets", text: "Airline Tickets" },
        ];
        $("#servicesBtn").prop("disabled");
        this.isDisabledServices = true;
        // this.$set(this.isLoadingArray, index, true);
      }
    },
  },
  mounted() {
    this.$root.$on("openServicesModal", (data) => {
      if (data) {
        if (data.singleEvent && data.userData) {
          this.startDate = data.singleEvent.startDateForField;
          this.endDate = data.singleEvent.endDateForField;
          this.user_id = data.userData.c_id;
          this.user_data = data.userData;
          this.checkdate();
        } else {
          this.startDate = data.startDateForField;
          this.endDate = data.endDateForField;
          this.checkdate();
        }
        var elem = this.$refs.openModalService;
        if (elem) {
          elem.click();
          this.validationserror = false;
          this.selectedArray = [];
          this.room_count = 1;
          this.adultQuantity = 1;
          this.note = "";
          this.showRoomInput = false;
          this.travelStatus = false;
          if (this.travelStatus == true) {
            $("#servicesBtn").prop("disabled");
            this.isDisabledServices = true;
          } else {
            $("#servicesBtn").prop("disabled", false);
            this.isDisabledServices = false;
          }
        }
      }
    });
  },
};
</script>

<style>
.custom-checkbox {
  border-bottom: 1px solid #cfd7e0;
  padding: 16px 0;
}
.custom-checkbox:last-child {
  border: none;
  padding-bottom: 0;
}
.custom-checkbox .custom-control-label::before {
  display: none !important;
}
.custom-control {
  padding-left: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#startDate {
  position: relative;
}
.vue-js-switch {
  margin-bottom: 0;
}

.product-decrement {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .form-control.is-invalid {
  background-image: none !important;
} */
</style>
