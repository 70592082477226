<template>
    <div class="filter-container">
      <!-- Year Dropdown -->
       <div class="filter-cotent">
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('year')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedYear
        }"
          @click="toggleDropdown('year')"
          
        >
          <span>{{ selectedYear }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'year'">
          <div
            v-for="year in years"
            :key="year"
            class="dropdown-item"
            @click="selectYear(year)"
          >
           <span> {{ year }} </span>
          </div>
        </div>
      </div>
  
      <!-- Organization Dropdown (Multi-select) -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('org')">
        <button class="dropdown-button" :class="{
          'has-value': selectedOrgs.length > 0
        }" @click="toggleDropdown('org')"
        >
          <span>{{ selectedOrgs.length >= 1 ? selectedOrgs[0] : 'Association' }}</span>
          <div class="badge-wrapper" v-if="selectedOrgs.length > 1">
            <span class="counter-badge">+{{ selectedOrgs.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'org'">
          <div v-for="org in organizations" :key="org.id" class="dropdown-item"
            :class="{ 'selected': selectedOrgs.includes(org.association_name) }" @click="toggleOrgSelection(org)">
            <input type="checkbox" :checked="selectedOrgs.includes(org.association_name)" class="checkbox">
            <span>{{ org.association_name }}</span>
          </div>
        </div>
      </div>
  
      <!-- Division Dropdown (Multi-select) -->
      <div class="dropdown-wrapper d-none" v-click-outside="() => closeDropdown('division')">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedDivisions
        }"
          @click="toggleDropdown('division')"
          
        >
          <span>{{ selectedDivisions.length === 1 ? selectedDivisions[0] : 'Dudley Lightening 65+' }}</span>
          <div class="badge-wrapper" v-if="selectedDivisions.length > 1">
            <span class="counter-badge">+{{ selectedDivisions.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'division'">
          <div
            v-for="division in divisions"
            :key="division"
            class="dropdown-item"
            :class="{ 'selected': selectedDivisions.includes(division) }"
            @click="toggleDivisionSelection(division)"
          >
            <input 
              type="checkbox" 
              :checked="selectedDivisions.includes(division)"
              class="checkbox"
            >
            <span>{{ division }}</span>
          </div>
        </div>
      </div>
  
      <!-- State Dropdown -->
      <!-- <div class="dropdown-wrapper">
        <button 
          class="dropdown-button"
          :class="{
          'has-value': selectedState
        }"
          @click="toggleDropdown('state')"
        >
          <span>{{ selectedState || 'State' }}</span>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'state'">
          <div
            v-for="state in states"
            :key="state"
            class="dropdown-item"
            @click="selectState(state)"
          >
            <span>{{state}}</span>
          </div>
        </div>
      </div> -->

      <!-- State Dropdown (Multi-select) -->
      <div class="dropdown-wrapper" v-click-outside="() => closeDropdown('state')">
        <button 
          class="dropdown-button"
          :class="{ 'has-value': selectedStates.length > 0 }"
          @click="toggleDropdown('state')"
          
        >

          <span>{{ selectedStates.length >= 1 ? selectedStates[0] : 'State' }}</span>
          <div class="badge-wrapper" v-if="selectedStates.length > 1">
            <span class="counter-badge">+{{ selectedStates.length - 1 }}</span>
          </div>
          <img :src="arrow_down" alt="arrow-down" />
        </button>
        <div class="dropdown-menu-content" v-show="activeDropdown === 'state'">
          <div
            v-for="state in stateOptions"
            :key="state.id"
            class="dropdown-item"
            :class="{ 'selected': selectedStates.includes(state.name) }"
            @click="toggleStateSelection(state)"
          >
            <input 
              type="checkbox" 
              :checked="selectedStates.includes(state.name)"
              class="checkbox"
            >
            <span>{{ state.name }}</span>
          </div>
        </div>
      </div>
  
      <!-- Additional Filter Buttons -->
      <button 
        class="filter-button" 
        :class="{ 'filter-button-active': isPastEventsActive }" 
        :disabled="isPastEventsDisabled"
        @click="togglePastEvents"
      >
          Past Events
      </button>

      <button class="filter-button d-none">Nearby</button>
      <button class="filter-button d-none">All Filters</button>
    </div>
      <button class="reset-button" @click="resetFilters" v-if="isResetVisible">Reset Filters</button>
    </div>
  </template>
  
<script>
let states = require("../../../assets/js/state");

export default {
  name: 'DiscoverEventsFilter',
  directives: {
    'click-outside': {
      bind(el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event);
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  data() {
    return {
      selectedEmitStates: [],
      activeDropdown: null,
      selectedYear: '2025',
      organizations: this.associationOptions,
      selectedOrgs: [],
      selectedDivisions: ['Dudley Lightening 65+'],
      selectedStates: [],
      years: ['2025', '2024', '2023'],
      divisions: [
        'Dudley Lightening 65+',
        'Dudley Thunder 50+',
        'Dudley Storm 40+',
      ],
      stateOptions: states,
      arrow_down: require("../../../assets/Svg/arrow-down.svg"),
      isPastEventsActive: false, // Track active state for Past Events
      isPastEventsDisabled: false, // Track active state for Past Events
    };
  },
  props: {
    associationOptions: {
      type: Array,
    },
   
  },
  computed: {
    isResetVisible() {
      return (
        this.selectedOrgs.length > 0 ||
        this.selectedStates.length > 0 ||
        this.isPastEventsActive
      );
    }
  },
  watch: {
    // Watcher for associationOptions
    associationOptions: {
      handler(newVal, oldVal) {
        console.log("associationOptions updated:");
        console.log("New Value:", newVal);
        console.log("Old Value:", oldVal);
        this.organizations = newVal;
      },
      
      deep: true, // Use this if you want to track changes within the array, e.g., object updates
      immediate: true, // Logs the initial value when the component is mounted
    },
  },
  methods: {
    toggleDropdown(dropdownName) {
      this.activeDropdown = this.activeDropdown === dropdownName ? null : dropdownName;
    },
    closeDropdown(dropdownName) {
      if (this.activeDropdown === dropdownName) {
        this.activeDropdown = null;
      }
    },
    selectYear(year) {
      this.selectedYear = year;
      this.closeDropdown('year');

      const currentYear = new Date().getFullYear();

      if (year < currentYear) {
        this.isPastEventsActive = true;
        this.isPastEventsDisabled = true; // Disable the button when a past year is selected
      } else {
        this.isPastEventsActive = false;
        this.isPastEventsDisabled = false; // Enable the button for the current or future year
      }

      this.emitFilterChange();
    },
    toggleOrgSelection(org) {
      console.log(this.organizations);
      const orgName = org.association_name;
      const index = this.selectedOrgs.indexOf(orgName);
      if (index === -1) {
        this.selectedOrgs.push(org.association_name); // Store the whole object
      } else {
        this.selectedOrgs.splice(index, 1); // Remove by index
      }
      this.emitFilterChange();
    },
    toggleDivisionSelection(division) {
      const divisionName = division.id;
      const index = this.selectedDivisions.indexOf(divisionName);
      if (index === -1) {
        this.selectedDivisions.push(divisionName); // Store the full object
      } else {
        this.selectedDivisions.splice(index, 1); // Remove by index
      }
      this.emitFilterChange();
    },
    toggleStateSelection(state) {
      const stateName = state.name; 
      const stateData = {
      name: state.name,
      abbreviation: state.abbreviation
    };
      const index = this.selectedStates.indexOf(stateName);
      console.log(this.selectedStates[0],"dd");
      if (index === -1) {
        this.selectedStates.push(stateName);
        this.selectedEmitStates.push(stateData);
      } else {
        this.selectedStates.splice(index, 1);
        this.selectedEmitStates.splice(index, 1);
      }
      this.emitFilterChange();
      // this.closeDropdown('state');

    },
    togglePastEvents() {
      if (this.isPastEventsDisabled) return; // Prevent unchecking when disabled
      this.isPastEventsActive = !this.isPastEventsActive;
      this.emitFilterChange();
    },
    // togglePastEvents() {
    //   this.isPastEventsActive = !this.isPastEventsActive;
    //   this.emitFilterChange();
    // },
    resetFilters() {
      this.selectedYear = '2025';
      this.selectedOrgs = [];
      this.selectedDivisions = ['Dudley Lightening 65+'];
      this.selectedStates = [];
      this.isPastEventsActive = false;
      this.selectedEmitStates= [],
      // Reset Past Events toggle
      this.emitFilterChange();
      
    },
    emitFilterChange() {
      this.$emit('filters-changed', {
        year: this.selectedYear,
        association: this.selectedOrgs,
        selectedDivisions: this.selectedDivisions,
        selectedStates: this.selectedEmitStates,
        eventStatus: this.isPastEventsActive,
      });
    },
  },
};
</script>

  <style scoped>
  .filter-button {
    cursor: pointer;
    transition: all 0.1s ease;
  }
  
  .filter-button-active {
    background: #e5f2fe;
  }
  </style>