<template>
  <div class="pb-3">
    <div class="row no-gutters">
      <div class="col-12 px-0">
      </div>
    </div>
    <div v-if="showLoaderOnPage == true" class="p-3">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
    <div
      class="row no-gutters"
      v-if="inviteArray.length != 0 && showLoaderOnPage == false"
    >
      <div
        class="col-12 col-sm-6 col-lg-4 col-xl-3 p-0 pr-sm-3 products-column"
        v-for="group in inviteArray"
        :key="group.id"
      >
        <div class="products-wrapper">
          <div class="join-content">
            <h4>{{ group.group_name }}</h4>
            <div class="join-group-img">
              <img
                v-if="group.group_image != null"
                :src="API_URL + group.group_image"
                alt=""
              />
              <img v-else src="../../assets/team-avatar.png" alt="group logo" />
            </div>
          </div>

          <div class="join-footer">
            <div class="event-btns-wrapper justify-content-center">
              <button
                v-if="isDisabled == false"
                type="button"
                class="btn btn-primary w-100"
                @click="joinGroup(group)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Join Team</span>
              </button>
              <button
                v-else-if="isDisabled == true"
                type="button"
                class="btn btn-primary-disabled w-100"
                :disabled="(true, isLoadingArray[0])"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Join Team</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <FlashMessage :position="'right bottom'"></FlashMessage> -->

    <div class="shop-search" v-if="showLoaderOnPage == false && inviteArray.length == 0">
        <div class="no-products-in-shop" >
          <img src="../../assets/images/team-grey.png" alt />
          <p class="header">No invites found.</p>
          <p class="info">
            There are no invites to show in team invites.
          </p>
        </div>
      </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import * as firestoreService from "../../database/firestore";

export default {
  name: "JoinGroup",
  props: {
    inviteArray: Array,
    showLoaderOnPage: Boolean,
  },
  data() {
    return {
      API_URL: this.$groupAvatarStorageURL,
      allUsersRef: [],
      isDisabled: false,
      groupId: "TIrcPBwLZhjEAg595Err",
      senderID: "",
      level: "",
      groupTeam: [],
      groupUsers: [],
      groupUsersRole: [],
      groupUsersCount: [],
      foundSentUser: "",
      currentUserID: localStorage.getItem("userId"),
      currentUserName: localStorage.getItem("userName"),
      linkUrl: "",
      errorMessage: "",
      isLoadingArray: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["group"]),
  },
  mounted() {},

  methods: {
    ...mapActions("notification", ["getUserNotificationData"]),
    async getRoomData(group) {
      const rawRoom = [];
      const promise = await firestoreService.getRoomById(
        group.group_id.toString()
      );
      rawRoom.push(promise);
      const foundRoom = await Promise.all(rawRoom);
      Object.keys(foundRoom).forEach((key) => {
        this.groupTeam = foundRoom[key];
      });
      if (this.groupTeam.users != undefined) {
        this.groupUsersRole = this.groupTeam.userRole;
        this.groupUsers = this.groupTeam.users;
        this.groupUsersCount = this.groupTeam.userCount;
        console.log(this.groupUsersCount,"add")
      } else {
        this.$fire({
          text: "Something went wrong, Please Try again.",
          type: "error",
          showConfirmButton: false,
          timer: 5000,
        }).then(() => {});
        //  this.$router.push({ name: "Teams" });
      }
    },
    async updateInviteStatus(group) {
  try {
    const formData = new FormData();
    formData.append("sendLinkUrl", group.c_id);
    formData.append("inviteID", group.id);

    const notifyId = localStorage.getItem("notifyId");
    if (notifyId) {
      formData.append("notifyId", notifyId);
    }

    // Await the Firestore call to get the user CID
    const userData = await firestoreService.getUserCid(group.user_link);
    const currentUserId = userData?.data?.[0]?.id;

    console.log(currentUserId, "Retrieved User CID");
    console.log(userData, "Full User Data Response");

    if (!currentUserId) {
      throw new Error("User CID not found.");
    }

    formData.append("userCID", currentUserId);

    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}invite/acceptInvite`,
      formData
    );

    if (response.data.statusCode === 200) {
      localStorage.removeItem("linkUrl");
      localStorage.removeItem("notifyId");
      this.getUserNotificationData();
      return true; // Success
    } else if (response.data.statusCode === 404) {
      this.errorMessage = "Invite not found or has been cancelled by the admin.";
      setTimeout(() => this.$alert(this.errorMessage), 500);
      return false; // Invite not found
    } else {
      this.errorMessage = response.data.message;
      setTimeout(() => this.$alert(this.errorMessage), 500);
      return false; // Other error
    }
  } catch (error) {
    console.error("Error updating invite status:", error);
    this.errorMessage = "Something went wrong. Please try again.";
    setTimeout(() => this.$alert(this.errorMessage), 500);
    return false;
  }
}
,

async joinGroup(group) {
  await this.getRoomData(group);
  this.isDisabled = true;
  let loader = this.$loading.show({
    // Optional parameters
    container: this.fullPage ? null : this.$refs.formContainer,
    color: "#ffffff",
    backgroundColor: "#333",
    opacity: 0.6,
  });

  const promise = await firestoreService.getUserCid(this.currentUserID.toString());

  // Stop proceeding if the invite status is 404
  const inviteStatus = await this.updateInviteStatus(group);
  if (!inviteStatus) {
    loader.hide();
    this.isDisabled = false;
    this.$root.$emit("removeItem", group.group_id);
    return; // Do not proceed further
  }

  // Create User if not exist
  var userExist = 0;
  this.groupUsersRole.forEach((user) => {
    if (promise.data[0].c_id == this.currentUserID) {
      if (user.id == promise.data[0].id) {
        userExist = 1;
      }
    }
  });

  if (userExist == 1) {
    loader.hide();
    this.isDisabled = false;
    this.$confirm("You have already joined the Team", "").then(() => {
      this.$root.$emit("removeItem", group.group_id);
      if (this.inviteArray.length == 1) {
        localStorage.setItem("openTabChat", "Team");
        this.$router.push({ name: "Teams" });
      }
    });
  } else if (userExist == 0) {
    if (promise.data[0].c_id == this.currentUserID) {
      this.foundSentUser = promise.data[0];
    }
    if (this.foundSentUser != "") {
      var arrayNonAdminUser = "";
      this.groupUsers.push(this.foundSentUser.id);
      arrayNonAdminUser = this.foundSentUser.id;

      // Add admin roles for non-admin
      const arrayAdminUserS = {
        id: arrayNonAdminUser,
        admin: "0",
        fan: group.level == "2" ? "1" : "0",
        notification: "on",
        status: 1,
      };
      this.groupUsersRole.push(arrayAdminUserS);
    }

    // Add counter array
    this.groupUsers.forEach((userId) => {
      const checkCount = {
        id: userId,
        count: 0,
      };
      this.groupUsersCount.push(checkCount);
    });

    await firestoreService.updateRoom(group.group_id, {
      users: this.groupUsers,
      userRole: this.groupUsersRole,
      userCount: this.groupUsersCount,
    });

    loader.hide();
    this.isDisabled = false;
    this.$confirm("Team has been joined successfully.", "").then(() => {
      this.$root.$emit("removeItem", group.group_id);
      if (this.inviteArray.length == 1) {
        localStorage.setItem("openTabChat", "Team");
        this.$router.push({ name: "Teams" });
      }
    });
  }
}

  },
};
</script>
<style scoped>
.join-wrapper {
  background-color: #ffffff;
  min-width: 300px;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  text-align: center;
}
.join-heading {
  padding: 16px 0;
  border-bottom: 1px solid #cdcdd0;
}
.join-heading h6 {
  font-size: 1rem;
}
.join-content {
  padding: 16px;
  height: 275px;
}
.join-content h4 {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  font-size: 16px;
}
.join-content .join-group-img {
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  margin: 16px auto 0 auto;
  border: 1px solid #cdcdd0;
  border-radius: 5px;
}
.join-content img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border-radius: 5px;
}
.join-footer {
  border-top: 1px solid #cdcdd0;
  padding: 16px;
}
.join-footer .btn {
  background-color: #188ef5;
  color: #ffffff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.no-products-in-shop{
  box-shadow: none!important;
}
</style>
